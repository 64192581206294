/*---------------------------------------*\
    App | Project Suggestion Slider
\*---------------------------------------*/

.project-suggestion-slider {
  margin-bottom: 0.8rem;

  &__slides {
    display: flex;

    swiper-container {
      width: 100%;
    }
  }

  h3 {
    text-align: center;
    margin: 1.6rem 0;
    font-weight: $fontweight-bold;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $color-dark;
  }

  swiper-slide {
    margin-right: 0.8rem;
    align-items: stretch;
  }
}
