/*------------------------------------*\
    App | DeviceUserData
\*------------------------------------*/

.device-data-loader {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    margin: 0 auto;
    margin-top: 0.8rem;
  }
}
