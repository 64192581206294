.tabs {
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 8.8rem;
    height: 4rem;
    background: linear-gradient(270deg, #ffffff 0%, rgba($color-white, 0) 100%);
    border-radius: 0 0 1.2rem 0;
    pointer-events: none;
  }

  &.sticky {
    background: $color-white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 600;
    padding-top: var(--safeAreaTop);
  }

  ion-segment {
    background: $color-white;
    padding-right: 5.6rem;
    box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
    border-radius: 0 0 1.2rem 1.2rem;

    &::-webkit-scrollbar {
      display: none;
    }

    ion-segment-button {
      font-family: 'Mukta', sans-serif;
      font-weight: $fontweight-medium;
      font-size: 1.4rem;
      line-height: 2.8rem;
      color: $color-dark;
      background: $color-white;
      border: 0;
      padding: 0.6rem 0;
      margin: 0;
      min-width: auto;
      --indicator-box-shadow: none;
      --indicator-color: none;

      &:focus {
        opacity: 0.5;
      }

      .notification-dot {
        position: absolute;
        width: 0.8rem;
        height: 0.8rem;
        background: $color-red;
        border-radius: 50%;
        right: -0.5rem;
        top: 0.6rem;
      }

      &:before {
        display: none;
      }

      &.segment-button-checked {
        background: $color-white;
        color: var(--appTint);
      }
    }
  }
}
