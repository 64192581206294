/*------------------------------------*\
    App | Projects
\*------------------------------------*/
.projects {
  display: flex;
  flex-direction: column;

  app-search-item:not(:last-child) {
    margin-bottom: 0.8rem;
    display: block;
  }
}
