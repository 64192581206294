app-file-input {
  label {
    display: block;
    width: 100%;
    height: 4rem;
    border: 0.1rem dashed $color-border;
    border-radius: 0.8rem;
    color: $color-border;
    text-align: center;
    position: relative;
    line-height: 4rem;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      span:first-child {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.2rem;

        svg {
          width: 100%;
          height: 100%;
          display: block;

          path {
            fill: $color-border;
          }
        }
      }

      span:last-child {
        line-height: 2rem;
        margin-left: 0.2rem;
        margin-top: 0.1rem;
        color: $color-border;
      }
    }

    .file-input {
      &__image {
        position: absolute;
        left: 0;
        top: 0;
        width: 4rem;
        height: 100%;
        padding: 0.2rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.8rem;
        }
      }

      &__clear {
        position: absolute;
        right: 0.8rem;
        top: 50%;
        transform: translateY(-50%);
        height: 1.6rem;
        width: 1.6rem;

        svg {
          width: 100%;
          height: 100%;
          display: block;

          path {
            fill: $color-red;
          }
        }
      }
    }
  }

  input {
    display: none;
  }
}
