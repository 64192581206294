// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
@import '@photo-sphere-viewer/core/index.css';

[hidden] {
  display: none !important;
}

.ion-activatable {
  position: relative;
  overflow: hidden;

  ion-ripple-effect {
    color: var(--appTint);
  }
}

ion-toast.toast-success {
  &::part(container) {
    background-color: var(--ion-color-medium);
    color: var(--ion-color-light);
    border-radius: var(--border-radius);
  }

  &::part(button) {
    color: var(--ion-color-light);
  }
}


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ion-spinner {
    &[color='primary'] {
      color: var(--appTint);
    }
  }

  &.padding {
    padding: 1.6rem;
  }
}

.loader--full {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: auto;
  height: auto;
}

.infinite-loading {
  --color: var(--appTint);
}

.refresher-refreshing-icon {
  --color: var(--appTint);
}

.action-sheet-button {
  &.targetBtn {
    color: #212121;
    white-space: nowrap;

    &.marquee {
      overflow: hidden;
      text-overflow: ellipsis;

      >span {
        display: inline-block;
        white-space: nowrap;
        transform: translateX(0);
        width: auto;
        animation: action-sheet-marquee linear 1s infinite;
      }
    }
  }

  &.cancelBtn {
    color: #fc5447;
  }

  &.filterBtn {
    color: #212121;

    &.ion-activated {
      color: #212121;
    }
  }

  &.settingsBtn {
    color: #212121;

    &.ion-activated {
      color: #212121;
    }
  }

  &.activeBtn {
    color: var(--appTint);

    &.ion-activated {
      color: var(--appTint);
    }
  }
}

@keyframes action-sheet-marquee {

  0%,
  10% {
    transform: translateX(0);
  }

  90%,
  100% {
    transform: translateX(calc(-100% + var(--action-sheet-width) - 36px));
  }
}