/*------------------------------------*\
    App | Buttons
\*------------------------------------*/
@mixin button {
  position: relative;
  cursor: pointer;
  border: 0;
  font-family: 'Mukta', sans-serif;
  font-weight: $fontweight-semibold;
  letter-spacing: 0;
  display: block;
  transition: 260ms ease;
  appearance: none;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.isLoading {
    opacity: 0.5;
    pointer-events: none;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button--s {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding: 0 1.2rem;
  border-radius: 0.8rem;
  height: 3.2rem;

  span {
    font-size: 1.5rem;
    line-height: 1.6rem;
  }
}

@mixin button--m {
  @include button--s;
  height: 4rem;
}

@mixin button--icon {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  span.icon {
    width: 1.6rem;
    min-width: 1.6rem;
    height: 1.6rem;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  span.icon + span:not(.icon),
  span:not(.icon) + span.icon {
    margin-left: 0.8rem;
  }
}

@mixin button--middle {
  width: 100%;
  text-align: center;
  padding-right: 0;
  padding-left: 0;
}

@mixin button--flat($background, $color) {
  color: $color;
  background: $background;
}

@mixin button--dashed($color-default, $color-inverted) {
  background: transparent;
  color: $color-default;
  border: 0.1rem dashed $color-default;

  &:hover {
    background: $color-default;
    color: $color-inverted;
  }
}

@mixin button--outlined($background, $color-default, $color-hover) {
  background: transparent;
  color: $color-default;
  box-shadow: inset 0 0 0 0.1rem $background;

  &:hover {
    background: $background;
    color: $color-hover;
  }
}
