/*------------------------------------*\
    App | Ionic
\*------------------------------------*/
body {
  font-size: 10px;

  app-home {
    background: $color-background;
  }

  ion-app {
    height: calc(100% - var(--keyboard-height));
  }

  ion-content {
    --ion-background-color: #f3f1ef;
  }

  ion-tab-bar {
    --background: #fff;
    border-radius: 2.4rem 2.4rem 0 0;
    box-shadow: 0 -0.2rem 0.4rem rgba($color-black, 0.06);
    height: 6.4rem;
    width: 100%;
    max-width: 76.8rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;

    ion-ripple-effect {
      width: 4.8rem;
      height: 4.8rem;
    }

    ion-tab-button {
      position: relative;
      width: 4.8rem;
      min-width: 4.8rem;
      max-width: 4.8rem;
      height: 4.8rem;
      margin: 0 1rem;
      --color: #212121;
      --color-selected: var(--appTint);

      ion-icon {
        width: 2.8rem;
        height: 2.8rem;
        margin: 0;
      }

      span {
        white-space: nowrap;
        position: absolute;
        top: 0.4rem;
        right: 0.2rem;
        width: 1.6rem;
        height: 1.6rem;
        background: $color-red;
        border-radius: 50%;
        font-weight: $fontweight-bold;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $color-white;
        text-align: center;
      }
    }
  }

  ion-tab-bar.ios {
    padding-bottom: calc(var(--ion-safe-area-bottom) * 0.5);
  }
}

.plt-desktop {
  body {
    ion-tab-bar {
      max-width: 100%;
      margin: 0;
      border-radius: 0;
    }
  }
}
