/*------------------------------------*\
    App | Survey
\*------------------------------------*/
.survey {
  width: 100%;
  height: 100%;
  background: $color-white;
  padding-bottom: 4rem;
  overflow-y: scroll;

  .form-group {
    margin: 0;

    .errors > div {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: $color-red;
    }
  }

  &__header {
    position: sticky;
    z-index: 5;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 6.4rem;
    background: $color-white;

    &::-webkit-scrollbar {
      display: none;
    }

    &__inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.4rem 1.6rem;
      max-width: 76.8rem;
      margin: 0 auto;

      > div:first-child {
        h1 {
          font-weight: $fontweight-bold;
          font-size: 1.8rem;
          line-height: 1.8rem;
          color: $color-dark;
        }

        span {
          display: block;
          font-weight: $fontweight-light;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: rgba($color-dark, 0.5);
        }
      }

      > div:last-child {
        margin-left: auto;
        text-align: center;
        width: 4rem;
        height: 4rem;
        cursor: pointer;
        padding: 0.8rem;

        span {
          display: inline-block;
          width: 2.4rem;
          height: 2.4rem;
        }

        &:hover {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &__progress {
    position: sticky;
    z-index: 4;
    top: 6.4rem;
    right: 0;
    left: 0;
    background: $color-grey--light;
    width: 100%;
    height: 0.8rem;

    &__inner {
      position: relative;
      transition: 0.5s all ease;

      > span {
        display: block;
        width: 100%;
        height: 0.8rem;
        background: var(--appSupport);
        border-radius: 0 1rem 1rem 0;
      }
    }

    > span {
      position: absolute;
      right: 0;
      left: 0;
      text-align: center;
      background: $color-white;
      top: 0.8rem;
      padding-top: 0.8rem;
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: var(--appSupport);
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    max-width: 76.8rem;
    margin: 0 auto;
    padding: 6.4rem 3.2rem 0 1.6rem;

    &::-webkit-scrollbar {
      display: none;
    }

    > h1 {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;
      margin-bottom: 0.8rem;
    }

    &.closing {
      text-align: center;

      h1 {
        padding: 1.6rem 8rem;
      }
    }
  }

  &__text {
    font-weight: $fontweight-regular;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: $color-dark;
    white-space: pre-wrap;
  }

  &__footer {
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    padding: 2.4rem 3.2rem 4rem 1.6rem;
    max-width: 76.8rem;
  }

  &__next {
    @include button;
    @include button--m;
    @include button--icon;
    @include button--flat(var(--appSupport), $color-white);
    margin-left: auto;
  }

  &__previous {
    @include button;
    @include button--m;
    @include button--icon;
    @include button--flat($color-grey, $color-dark);
  }

  &__start {
    @include button;
    @include button--m;
    @include button--icon;
    @include button--flat(var(--appSupport), $color-white);
    margin: auto;
  }

  &__radio,
  &__checkbox {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 1.6rem 0.8rem;

    li:not(:last-child) {
      margin-bottom: 1.2rem;
    }

    input[type='radio'],
    input[type='checkbox'] {
      display: none;
      @include checkbox;
    }

    input[type='radio'] {
      @include radio;
    }
  }

  &__scale {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin-top: 2.4rem;
    padding: 0;

    li {
      margin: 0 1.2rem;
      width: 3.2rem;
      text-align: center;

      input[type='radio'] {
        @include radio;

        + label {
          padding: 0 0 3.2rem 0;

          &:before,
          &:after {
            top: auto;
            bottom: 0;
            left: 0.6rem;
          }

          &:after {
            bottom: 0.4rem;
            left: 1rem;
          }
        }
      }
    }
  }

  &__emojis {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin: 2.4rem 0 0 0;
    padding: 0;

    > div {
      width: 5.6rem;
      height: 5.6rem;

      &:not(:last-child) {
        margin-right: 4rem;
      }

      input[type='radio'] {
        display: none;

        + label {
          display: block;
          width: 100%;
          height: 100%;
          cursor: pointer;

          svg {
            width: 5.6rem;
            height: 5.6rem;

            svg path {
              &:nth-child(1) {
                transition: 120ms ease;
              }
            }
          }

          &:hover {
            svg path {
              &:nth-child(1) {
                fill: var(--appSupport);
              }

              &:nth-child(2) {
                stroke: $color-white;
              }

              &:nth-child(3),
              &:nth-child(4) {
                fill: $color-white;
              }
            }

            svg g circle {
              fill: var(--appTint) !important;
            }
          }
        }
      }

      input[type='radio']:checked + label {
        svg {
          path {
            &:nth-child(1) {
              fill: var(--appSupport);
            }

            &:nth-child(2) {
              stroke: $color-white;
            }

            &:nth-child(3),
            &:nth-child(4) {
              fill: $color-white;
            }
          }

          g circle {
            fill: var(--appTint) !important;
          }
        }
      }
    }
  }

  &__skip {
    margin-top: 4rem;
    text-align: center;

    input {
      display: none;

      + label {
        display: inline-block;
        padding: 0.8rem 1.6rem;
        background: $color-white;
        border-radius: 0.6rem;
        font-weight: $fontweight-semibold;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: rgba($color-dark, 0.5);
        cursor: pointer;
        transition: 260ms ease;

        &:hover {
          background: $color-grey;
          color: $color-dark;
        }
      }
    }

    input:checked + label {
      background: $color-grey;
      color: $color-dark;
    }
  }

  &__textarea {
    margin-top: 1.6rem;

    textarea {
      width: 100%;
      height: auto;
      min-height: 12rem;
      border: 0.1rem solid $color-border;
      border-radius: 0.6rem;
      padding: 1.6rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-black;
    }
  }

  &__finish {
    @include button;
    @include button--m;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    max-width: 160px;
    margin: 2.4rem auto;
    @include button--icon;
    @include button--flat(var(--appTint), $color-white);
    margin-top: 2.4rem;
  }

  &__warning {
    width: 100%;
    height: 100%;
    padding: 14.4rem 5.6rem;
    text-align: center;

    > h2 {
      font-weight: $fontweight-bold;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: $color-dark;
      margin: 0;
    }

    > p {
      max-width: 28rem;
      margin: 0.8rem auto 2.4rem auto;
      font-weight: $fontweight-regular;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-black;

      span {
        font-weight: $fontweight-bold;
        color: var(--appTint);
      }
    }

    > .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;

      > button {
        @include button;
        @include button--m;
        margin: 0 0.8rem;

        &.bg--red {
          @include button--flat($color-red, $color-white);
        }

        &.bg--white {
          @include button--flat($color-grey, $color-dark);
        }
      }
    }
  }
}

@media screen and (max-width: $screen--m-max) {
  .survey {
    padding-bottom: 12rem;

    &__content {
      > h1 {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }

      &.closing {
        h1 {
          padding: 0;
          margin: 0;
        }
      }
    }

    &__footer {
      position: fixed;
      background: $color-white;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 1.2rem 1.6rem;
      max-width: 100%;
      box-shadow: 0 -0.2rem 0.4rem 0 rgba($color-black, 0.08);

      &.middle {
        .survey__next {
          margin: 0 auto;
        }
      }
    }

    &__scale {
      li {
        margin: 0 0.6rem;
      }
    }

    &__emojis {
      > div {
        width: 4.8rem;
        min-width: 4.8rem;
        height: 4.8rem;

        &:not(:last-child) {
          margin-right: 2rem;
        }

        input[type='radio'] + label {
          svg {
            width: 4.8rem;
            height: 4.8rem;
          }
        }
      }
    }
  }
}

.plt-desktop {
  .survey {
    &__next,
    &__previous,
    &__start,
    &__finish {
      &:hover {
        filter: brightness(90%);
      }
    }
  }
}
