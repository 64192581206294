.message {
  font-family: 'Mukta', sans-serif;
  background: $color-white;
  --ion-background-color: $color-white;

  &__top,
  &__header,
  &__content {
    max-width: 76.8rem;
    margin: 0 auto;
  }

  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.6rem 1.6rem 0 1.6rem;
  }

  &__back {
    position: relative;
    width: 3.2rem;
    min-width: 3.2rem;
    height: 3.2rem;

    &:before {
      content: '';
      position: absolute;
      top: 0.4rem;
      left: 0.4rem;
      width: 2.4rem;
      min-width: 2.4rem;
      height: 2.4rem;
      background-image: url('/assets/icons/arrow-left-thin.svg');
      background-size: 2.4rem;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $color-white;
      pointer-events: none;
    }

    ion-back-button {
      display: block;
      width: 3.2rem;
      min-width: 3.2rem;
      max-width: 3.2rem;
      height: 3.2rem;
      color: transparent;
    }
  }

  &__header {
    position: relative;
    background: $color-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.2rem 1.6rem 0.4rem 1.6rem;
  }

  &__image {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    border-radius: 0.6rem;

    img {
      width: 4rem;
      height: 4rem;
      border-radius: 0.6rem;
      object-fit: cover;
    }
  }

  &__share {
    align-self: start;
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
  }

  &__info {
    width: calc(100% - 8rem);
    min-width: calc(100% - 8rem);
    padding-left: 1.2rem;
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-dark;
    }

    span:nth-child(2) {
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2rem;
      color: rgba($color-dark, 0.4);
    }

    span:last-child {
      font-weight: $fontweight-light;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: $color-dark;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 1.6rem;

    h2 {
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 1.6rem;
    }

    > span {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: rgba($color-dark, 0.4);
    }

    > div {
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-dark;

      a,
      p,
      strong,
      u,
      em,
      li {
        font-weight: $fontweight-regular;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      ul,
      ol {
        padding-left: 3.2rem;
        margin: 1.6rem 0;
      }

      ul li {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }

      strong {
        font-weight: $fontweight-semibold;
      }

      a {
        color: var(--appTint);
      }
    }
  }

  &__bottom {
    padding: 1.2rem 1.6rem;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    a {
      margin-left: auto;
      font-size: 1.2rem;
      color: $color-grey--dark;
    }

    span {
      margin-left: 0.6rem;
      font-weight: $fontweight-regular;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $color-grey--dark;
    }
  }
}
