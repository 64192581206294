/*------------------------------------*\
    App | Back button
\*------------------------------------*/
.back-button {
  position: relative;
  overflow: hidden;
  width: 3.2rem;
  min-width: 3.2rem;
  height: 3.2rem;
  border-radius: 0.4rem;
  display: block;
  margin-right: 1rem;
  transition: transform 0.3s ease-in-out;
  &:before {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    background-image: url('/assets/icons/arrow-left-thin.svg');
    background-size: 2.4rem;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    pointer-events: none;
  }

  > span {
    display: block;
  }

  ion-back-button {
    display: block;
    width: 3.2rem;
    min-width: 3.2rem;
    max-width: 3.2rem;
    height: 3.2rem;
    color: transparent;

    &:focus {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.plt-desktop {
  .back-button:hover {
    cursor: pointer;
    transform: scale(1.5);
  }
}
