/*------------------------------------*\
    App | Smart reporting (overview)
\*------------------------------------*/
.smart-reporting {
  &__map {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 29rem;

    &--fullscreen {
      top: 0;
    }

    &__inner {
      width: 100%;
      height: 100%;

      .overlay {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .projects-map {
      &__marker {
        width: 50vw;
      }

      &__logo {
        height: 2rem;
        width: 60px;
        margin: auto;
        text-align: center;
        border-radius: 50% 50% 0 0;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &__text {
        text-align: center;
        font-weight: $fontweight-bold;
        font-size: 1.2rem;
        line-height: 2rem;
        color: $color-dark;
        background: white;
        min-width: 100px;
        border-radius: 10px;
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
        padding: 0 10px;
        max-width: 200px;
      }
    }
  }

  &__new {
    @include button;
    @include button--m;
    @include button--icon;
    @include button--flat(var(--appSupport), $color-white);
    position: absolute;
    bottom: 4rem;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    min-width: 14rem;

    &:hover {
      filter: brightness(80%);
    }
  }

  &__filter {
    @include button;
    @include button--m;
    @include button--icon;
    @include button--flat($color-dark, $color-white);
    position: absolute;
    z-index: 8;
    top: 6.4rem;
    right: 2.4rem;

    span.icon {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;

      svg {
        width: 2rem;
        height: 2rem;

        path {
          fill: $color-white;
        }
      }
    }
  }

  &__overlay {
    position: fixed;
    z-index: 50;
    bottom: 0;
    right: 0;
    left: 0;
    background: $color-white;
    box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
    border-radius: 2.4rem 2.4rem 0 0;
    padding: 1.6rem;

    > p {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 1.6rem;
      color: $color-dark;
    }

    > ul {
      padding: 0.4rem 0 1.2rem 0;

      > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.2rem 0;
        border-bottom: 0.1rem solid rgba($color-border, 0.5);

        &:last-child {
          border: 0;
        }

        &.own span.icon svg path {
          fill: $color-purple;
        }

        &.subscribed span.icon svg path {
          fill: $color-red;
        }

        > span.icon {
          width: 2.4rem;
          height: 2.4rem;
          min-width: 2.4rem;
        }

        > span:not(.icon) {
          width: calc(100% - 8rem);
          min-width: calc(100% - 8rem);
          padding-left: 0.8rem;
          font-weight: $fontweight-regular;
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: $color-dark;
        }

        > div {
          margin-left: auto;

          input[type='checkbox'] {
            @include toggle;
          }
        }
      }
    }

    button {
      @include button;
      @include button--m;
      @include button--flat($color-dark, $color-white);
      width: 18rem;
      margin: 0 auto;
    }
  }
}

/*------------------------------------*\
    App | Smart reporting (modal)
\*------------------------------------*/
.smart-reporting-modal {
  height: 33%;
  width: 50%;
  margin: auto auto;
}

/*------------------------------------*\
    App | Smart reporting (detail)
\*------------------------------------*/
.smart-reporting-detail {
  background: $color-white;
  --ion-background-color: $color-white;

  &__inner {
    padding: 1.6rem;
  }

  &__head {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > div:nth-child(2) {
      width: calc(100% - 3.2rem);
      min-width: calc(100% - 3.2rem);
    }

    > div:nth-child(3) {
      width: 100%;
      min-width: 100%;
      padding: 1.2rem 0 0.8rem 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__text {
      width: calc(100% - 16rem);
      min-width: calc(100% - 16rem);
      padding-right: 2.4rem;

      h1,
      h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h1 {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $color-dark;
      }

      span {
        font-weight: $fontweight-bold;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: var(--appTint);
      }
    }

    &__steps {
      width: 16rem;
      min-width: 16rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      > div {
        position: relative;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        border: 0.1rem solid $color-border;
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $color-border;
        text-align: center;
        padding: 0.3rem 0;

        &:not(:first-child) {
          margin-left: 3.2rem;
        }

        &:not(:last-child) {
          &:after {
            content: '';
            position: absolute;
            top: 1.5rem;
            right: -2.9rem;
            width: 2.4rem;
            height: 0.1rem;
            background: $color-border;
          }
        }

        &.active {
          background: var(--appTint);
          border-color: var(--appTint);
          color: $color-white;

          &:after {
            background: var(--appTint);
          }
        }
      }
    }
  }

  &__step {
    > p {
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: rgba($color-dark, 0.5);
      margin-bottom: 1.2rem;
    }

    .form-group {
      margin-bottom: 1.6rem;

      label {
        font-weight: $fontweight-medium;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $color-dark;
        display: block;
        margin-bottom: 0.4rem;

        span.required {
          font-weight: $fontweight-bold;
          color: $color-red;
        }
      }

      input,
      textarea {
        @include input;
        @include input--s;
      }

      textarea {
        @include textarea;
      }

      select {
        @include select;
        @include select--s;
      }
    }

    .smart-reporting__map {
      top: 0;
    }

    &.noDescription {
      padding-top: 1.2rem;
    }
  }

  &__form {
    &.location {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      > app-form-group {
        &:first-child {
          width: calc(10rem - 0.8rem);
          min-width: calc(10rem - 0.8rem);
        }

        &:nth-child(2) {
          width: calc(100% - 10.8rem);
          min-width: calc(100% - 10.8rem);
        }

        &:nth-child(3) {
          width: calc(100% - 11.8rem);
          min-width: calc(100% - 11.8rem);
        }

        &:last-child {
          width: calc(11rem - 0.8rem);
          min-width: calc(11rem - 0.8rem);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .errors {
      margin-top: 0.4rem;
      font-weight: $fontweight-medium;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: $color-red;
    }

    &__notice {
      width: 100%;
      min-width: 100%;
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: $color-red;
      padding-bottom: 1.6rem;
      text-align: center;
    }
  }

  &__map {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    top: 35.6rem;
    border-radius: 2.4rem 2.4rem 0 0;
    overflow: hidden;

    &--fullscreen {
      top: 0;
    }

    &__inner {
      width: 100%;
      height: 100%;
    }

    .marker {
      background-repeat: no-repeat !important;
    }
  }

  &__button {
    @include button;
    @include button--m;
    @include button--flat(var(--appSupport), $color-white);
    width: 18rem;
    margin: 2.4rem auto 0 auto;

    &--fixed {
      position: absolute;
      z-index: 20;
      bottom: 3.2rem;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }
  }

  &__success {
    padding: 1.2rem 0 4rem 0;

    > h1 {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;
    }

    > p {
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: rgba($color-dark, 0.5);

      strong {
        color: var(--appSupport);
      }
    }

    > span {
      display: block;
      text-align: center;
      margin: 4rem 0;
    }
  }
}

.plt-desktop {
  .smart-reporting {
    &__overlay {
      button:hover {
        color: $color-dark;
        background-color: $color-white;
        box-shadow: inset 0 0 0 0.1rem $color-dark;
      }
    }

    &__filter:hover {
      color: $color-dark;
      background-color: $color-white;
      box-shadow: inset 0 0 0 0.1rem $color-dark;

      svg {
        path {
          fill: $color-dark;
        }
      }
    }
  }

  .smart-reporting-detail {
    &__button {
      &:hover {
        filter: brightness(90%);
      }
    }

    &__head {
      &__steps {
        div:hover {
          cursor: pointer;
          background: var(--appTint);
          border-color: var(--appTint);
          color: #fff;
        }
      }
    }

    &__step {
      .form-group {
        select:hover {
          cursor: pointer;
        }

        .file-input__media:hover {
          cursor: pointer;
        }
      }
    }
  }
}
