/*------------------------------------*\
    App | Form Elements: Checkbox
\*------------------------------------*/
@mixin checkbox {
  display: none;

  + label {
    display: block;
    position: relative;
    font-weight: $fontweight-regular;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-dark;
    text-decoration: none;
    transition: 260ms ease;
    padding-left: 3.2rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;

      &:before {
        border-color: var(--appTint);
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      border-radius: 0.4rem;
    }

    &:before {
      left: 0;
      width: 2rem;
      height: 2rem;
      margin-top: -1.1rem;
      background: $color-white;
      border: 0.1rem solid $color-border;
      transition: 260ms ease;
    }

    &:after {
      left: 0.4rem;
      width: 1.2rem;
      height: 1.2rem;
      margin-top: -0.7rem;
      background: var(--appTint);
      opacity: 0;
      pointer-events: none;
      transition: 260ms ease;
    }
  }

  &:checked + label {
    &:before {
      border-color: var(--appTint);
    }

    &:after {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

/*------------------------------------*\
    App | Form Elements: Radio
\*------------------------------------*/
@mixin radio {
  @include checkbox;

  + label {
    &:before,
    &:after {
      border-radius: 50%;
    }

    &.poll_closed {
      padding-left: 0;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

/*------------------------------------*\
    App | Form Elements: Toggle
\*------------------------------------*/
@mixin toggle {
  display: none;

  + label {
    display: block;
    position: relative;
    cursor: pointer;
    width: 4.4rem;
    min-width: 4.4rem;
    height: 2.4rem;
    background: $color-grey--light;
    border-radius: 10rem;
    transition: 260ms ease;

    &:after {
      content: '';
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: $color-white;
      box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
      transition: 260ms ease;
      pointer-events: none;
    }
  }

  &:checked + label {
    background: var(--appTint);

    &:after {
      transform: translateX(2rem);
    }
  }
}

/*------------------------------------*\
    App | Form Elements: Input
\*------------------------------------*/
@mixin input {
  font-family: 'Mukta', sans-serif;
  font-weight: $fontweight-medium;
  width: 100%;
  border: 0.1rem solid rgba($color-border, 0.5);
  appearance: none;
  border-radius: 0.8rem;
  color: $color-dark;
}

@mixin input--s {
  height: 4rem;
  padding: 0 1.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

@mixin input--m {
  font-size: 1.6rem;
  height: 5.6rem;
  border-radius: 5.6rem;
  padding: 0 2.4rem;
}

@mixin textarea {
  padding: 1.2rem 1.6rem;
  height: 14.4rem;
  resize: none;
}

/*------------------------------------*\
    App | Form Elements: Select
\*------------------------------------*/
@mixin select {
  width: 100%;
  border: 0.1rem solid rgba($color-border, 0.5);
  font-family: 'Mukta', sans-serif;
  font-weight: $fontweight-medium;
  background-image: url('/assets/icons/arrow-down.svg');
  background-size: 1.6rem;
  background-position: right 1.2rem top 50%, 0 0;
  background-repeat: no-repeat;
  appearance: none;
  color: $color-dark;
  border-radius: 0.8rem;
}

@mixin select--s {
  height: 4rem;
  padding: 0 1.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

@mixin select--m {
  height: 5.6rem;
  border-radius: 5.6rem;
  padding: 0 2.4rem;
}
