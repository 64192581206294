/*------------------------------------*\
    App | Organization
\*------------------------------------*/
.organization {
  font-family: 'Mukta', sans-serif;

  &__header,
  &__content {
    max-width: 76.8rem;
    margin: 0 auto;
  }

  &__header {
    position: relative;
    z-index: 10;
    background: $color-white;
    border-radius: 0 0 1.2rem 1.2rem;

    &.dark {
      background-color: $color-dark;

      .organization__header__cover {
        background-color: $color-dark;
      }

      .organization__info {
        h1,
        span,
        li {
          color: $color-white;
        }
      }

      .organization__about {
        a {
          color: $color-white;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0rem 1.2rem 0rem 1.2rem;
      border-bottom: 0.1rem solid $color-background;
    }

    > div:first-child {
      position: relative;
      padding: 0.8rem 0.8rem 0rem 0.8rem;

      .back-button {
        background: $color-white;
      }
    }
  }

  &__image {
    max-width: calc(100% - 4.8rem);
    height: 9.6rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    padding: 2.4rem 0rem 2.4rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.8rem;
    font-size: 1.2rem;

    h1 {
      font-weight: $fontweight-bold;
      font-size: 2.4rem;
      color: $color-dark;
    }

    &__followers {
      color: var(--appTint);
    }

    > div:last-child {
      color: $color-text;
    }
  }

  &__content {
    padding: 0.8rem;
    min-height: calc(100% - 31.7rem);

    .card {
      margin-bottom: 0.8rem;
    }
  }
}
