/*------------------------------------*\
    App | Globals
\*------------------------------------*/
:root {
  font-size: 10px;
}

html,
body {
  overscroll-behavior-x: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: 'Mukta', sans-serif;
  font-weight: $fontweight-regular;
  font-size: 10px;
}

html {
  --width: 100vw;
  height: 100%;
}

body {
  --keyboard-height: 0px;
  --screenHeight: 100vh;
  height: var(--screenHeight);
}

.swipe-area,
.swipe-content {
  height: 100%;
  width: 100%;
}

.loading-overlay {
  background: $color-white;
}

ion-content.ios {
  --padding-top: calc(0.8 * var(--safeAreaTop));
}

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  max-width: 60%;
  margin: 0 auto;
  text-align: center;

  &.no-padding {
    padding-top: 0;
  }

  svg {
    width: 6rem;
    height: 6rem;

    path {
      fill: rgba($color-text, 0.5);
    }
  }

  h2 {
    margin: 0.8rem 0;
    font-weight: $fontweight-bold;
    font-size: 2rem;
    line-height: 2.8rem;
    color: $color-dark;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .large-svg {
    svg {
      width: 12rem;
      height: 12rem;
    }
  }
}

app-project-updates {
  .project__content__filter ~ .empty-list {
    padding-top: 5rem;
  }
}

/*------------------------------------*\
    App | Globals
\*------------------------------------*/
.app {
  font-family: 'Mukta', sans-serif;

  &__header,
  &__content,
  &__description {
    max-width: 76.8rem;
    margin: 0 auto;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.6rem 2.4rem;
    font-weight: $fontweight-bold;
    font-size: 2rem;
    line-height: 3.2rem;
    color: $color-dark;

    h1 {
      font-size: 2rem;
      line-height: 3.2rem;
      font-weight: $fontweight-bold;
    }

    &.hasDescription {
      padding-bottom: 0;
    }

    &__buttons {
      margin-left: auto;
      display: flex;
      align-items: center;
      pointer-events: all;
      cursor: pointer;
      position: relative;

      > div > span {
        &:first-child {
          font-size: 1.4rem;
          padding-left: 0.8rem;
          font-weight: $fontweight-bold;
          height: 3rem;
          line-height: 3rem;

          svg {
            height: 100%;
          }
        }

        &:nth-child(2) {
          white-space: nowrap;
          position: absolute;
          top: -0.5rem;
          right: -0.5rem;
          width: 1.6rem;
          height: 1.6rem;
          background: #fc5447;
          border-radius: 50%;
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: #fff;
          text-align: center;
        }
      }
    }
  }

  &__description {
    font-weight: $fontweight-regular;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: $color-dark;
    padding: 0 2.4rem 1.6rem 2.4rem;
  }

  &__content {
    padding: 0 0.8rem 2.4rem 0.8rem;
    height: 100%;
    width: 100%;

    &.no-padding {
      padding: 0;
    }
  }
}

.plt-desktop {
  .app {
    &__header {
      &__buttons {
        div {
          span:hover {
            svg {
              fill: var(--appTint);
            }
          }
        }
      }
    }
  }
}
