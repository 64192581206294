/*------------------------------------*\
    App | Card, Child
\*------------------------------------*/
.card--child {
  transition: transform 0.3s ease-in-out;
  $self: &;
  padding: 1.2rem 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__image {
    width: 4.8rem;
    min-width: 4.8rem;
    height: 4.8rem;
    border-radius: 0.6rem;

    img {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 0.6rem;
      object-fit: cover;
    }
  }

  &__content {
    width: calc(100% - 10.4rem);
    min-width: calc(100% - 10.4rem);
    padding-left: 1.6rem;
    display: flex;
    flex-direction: column;

    span {
      display: block;
      font-weight: $fontweight-regular;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: $color-dark;

      &.location {
        font-weight: $fontweight-bold;
        color: var(--appTint);
      }

      &:first-child {
        color: var(--appTint);
      }

      &:last-child {
        color: rgba($color-dark, 0.5);
      }
    }

    h2,
    h3 {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-dark;
    }
  }

  &__icon {
    margin-left: auto;
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    text-align: center;
    padding: 1rem;
  }
}

.plt-desktop {
  .card--child:hover {
    cursor: pointer;
    transform: scale(1.025);
  }
}
