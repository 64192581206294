/*------------------------------------*\
    App | Card, Poll
\*------------------------------------*/
.card {
  &__poll {
    position: relative;
    margin-top: 1.6rem;
    padding-top: 1.6rem;

    p.poll_closed_text {
      font-size: 1.6rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 75%;
      height: 0.1rem;
      background: rgba($color-border, 0.5);
      transform: translateX(-50%);
    }

    > span {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;
    }

    &__questions {
      margin: 0.8rem 0;

      &:has(.active) {
        //pointer-events: none;
      }

      &:has(.image) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0.8rem -0.8rem 0 -0.8rem;

        > li {
          margin: 0 0.8rem;
        }
      }

      > li {
        &:not(:last-child) {
          margin-bottom: 0.8rem;
        }

        input[type='radio'] {
          @include radio;
          + label {
            display: flex;
            align-items: center;
          }
        }

        strong {
          padding-left: 0.4rem;
          color: rgba($color-dark, 0.3);
        }

        &.active {
          //pointer-events: none;

          input[type='radio'] + label,
          strong {
            color: var(--appTint);
          }
        }
      }

      &__text {
        flex-grow: 1;
        overflow: hidden;
      }

      &__image {
        flex-shrink: 0;
        display: inline-block;
        width: 10rem;
        height: 10rem;
        border-radius: 0.6rem;
        overflow: hidden;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 5px;

        &:before,
        &:after {
          top: auto;
          left: 50%;
          transform: translateX(-50%);
          margin: 0;
        }

        span {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 3.8rem 1.6rem;
          border-radius: 0.6rem;
          text-align: center;
          font-weight: $fontweight-bold;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: $color-white;
          background: rgba($color-dark, 0.5);
        }
      }
    }

    &__votes {
      font-weight: $fontweight-regular;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $color-grey--dark;
    }
  }
}
