/*------------------------------------*\
    App | Card, Conversation
\*------------------------------------*/
.card--conversation {
  transition: transform 0.3s ease-in-out;
  &__meta {
    display: flex;
    flex-direction: row;

    span {
      font-size: 1.4rem;
      line-height: 2rem;

      &:first-child {
        width: calc(100% - 8rem);
        min-width: calc(100% - 8rem);
        padding-right: 1.6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: $fontweight-bold;
        color: $color-dark;
      }

      &:last-child {
        width: 8rem;
        min-width: 8rem;
        font-weight: $fontweight-regular;
        color: $color-border;
        text-align: right;
      }
    }
  }

  &__text {
    margin-top: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > div {
      width: calc(100% - 2rem);
      min-width: calc(100% - 2rem);
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2rem;
      color: rgba($color-dark, 0.5);
      padding-right: 4rem;
      height: 2rem;
      overflow: hidden;

      ul {
        padding-left: 2.5rem;
        margin: 0;

        > li {
          list-style-type: disc;
          padding-left: 0.5rem;
        }
      }

      ol {
        padding-left: 3rem;
        margin: 0;

        > li {
          list-style-type: decimal;
        }
      }

      a {
        color: unset;
      }
    }

    > span {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;

      svg {
        path {
          fill: $color-dark;
        }
      }
    }
  }
}

.plt-desktop {
  .card--conversation:hover {
    cursor: pointer;
    transform: scale(1.025);
  }
}
