.not-found__holder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Mukta', sans-serif;

  &__content {
    padding-top: 20rem;
    text-align: center;

    h1 {
      position: relative;
      font-weight: $fontweight-bold;
      font-size: 8rem;
      line-height: 8rem;
      color: $color-dark;

      &:before {
        content: '';
        position: absolute;
        top: -4.2rem;
        right: -4.8rem;
        width: 6rem;
        min-width: 6rem;
        height: 6rem;
        background-image: url('/assets/icons/flag.svg');
        background-size: 6rem;
        background-repeat: no-repeat;
        background-position: center;
        pointer-events: none;
        z-index: -1;
      }
    }

    p {
      font-weight: $fontweight-light;
      font-size: 2.2rem;
      line-height: 2.2rem;
      color: $color-dark;
      text-transform: lowercase;
    }

    button {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--flat(var(--appTint), $color-white);
      margin-top: 4rem;
    }
  }
}
