/*------------------------------------*\
    App | Address
\*------------------------------------*/

.address {
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.with-header {
    height: calc(100% - 6.4rem);
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1.6rem 2.4rem;
    height: 6.2rem;

    button {
      font-size: 1.4rem;
      font-weight: bold;
      height: 3rem;
      line-height: 3.2rem;
      color: var(--appTint);
      margin-left: auto;
      cursor: pointer;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 76.8rem;
    margin: 0 auto;
    padding: 1.6rem 1.6rem var(--safeAreaBottom) 1.6rem;
    text-align: center;
    background-color: $color-white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &__button {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--flat(var(--appTint), $color-white);
      padding: 0 4rem;
    }

    &__button--secondary {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--outlined(
        $color-grey--dark,
        $color-dark,
        $color-background
      );
      padding: 0 4rem;
    }
  }

  &__content {
    padding: 2.4rem;
    transition: all 0.25s ease;

    &.map {
      margin-top: -2rem;
      height: 33%;
      border-radius: 2rem 2rem 0 0;
      background-color: var(--ion-background-color);
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.fullscreen {
      &.map {
        z-index: unset;
      }

      button.follow {
        position: absolute;
        bottom: 1.6rem;
        margin-bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
      }
    }

    &.no-padding {
      padding: 0 2.4rem 1.6rem 2.4rem;

      .address__content__input {
        margin: 0;
      }
    }

    &.list,
    &.suggestions {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &.suggestions {
      height: calc(100% - 6.2rem);
    }

    &__top {
      text-align: center;
      padding: 0 1.6rem;

      &.padding {
        padding-top: 6.4rem;
      }

      h1 {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $color-dark;
        margin-bottom: 0.8rem;
      }

      p {
        font-weight: $fontweight-medium;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $color-dark;

        &.hidden {
          visibility: hidden;
        }
      }

      button {
        @include button;
        @include button--m;
        @include button--flat(var(--appTint), $color-white);
        margin: 0 auto;
        margin-top: 2.4rem;
        margin-bottom: 0.8rem;
        transition: none;
      }
    }

    &__results {
      margin-top: 2.6rem;
      overflow-y: overlay;
      padding-bottom: 4.8rem;

      app-search-item {
        margin-bottom: 0.8rem;
        display: block;

        .card__content {
          > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    &__locations {
      margin-top: 2.6rem;
      padding-bottom: 4.8rem;

      div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;

        h3 {
          font-weight: $fontweight-bold;
          font-size: 1.6rem;
          line-height: 2.2rem;
          color: $color-dark;
        }

        a {
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: $color-red;
          margin-left: auto;
          font-weight: $fontweight-medium;
          cursor: pointer;
        }
      }

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.6rem;
        margin: 0.8rem 0;
        background-color: $color-white;
        border-radius: 0.8rem;
        box-shadow: 0px 2px 2px $color-grey--light;
        position: relative;

        .count {
          position: absolute;
          left: -10px;
          top: 10px;
          background-color: #212121;
          border-radius: 50%;
          width: 2.4rem;
          height: 2.4rem;
          color: #fff;
          line-height: 2.4rem;
          font-size: 1.4rem;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.new {
          background: none;
          border: 1px dashed $color-grey--dark;
          box-shadow: none;
          cursor: pointer;

          span svg {
            fill: $color-grey--dark;
          }

          p {
            font-weight: $fontweight-regular;
            font-size: 1.6rem;
            height: 2.4rem;
            color: $color-grey--dark;
            padding: 0 1.6rem;
          }
        }

        span {
          width: 2.4rem;
          height: 2.4rem;
        }

        button {
          width: 4rem;
          height: 4rem;
          margin-left: auto;
          background: none;
        }

        &__content {
          width: calc(100% - 6.4rem);
          display: flex;
          flex-direction: column;
          padding: 0 1.6rem;

          p {
            font-weight: $fontweight-regular;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: $color-dark;

            &:last-child {
              font-weight: $fontweight-medium;
              font-size: 1.2rem;
            }
          }
        }
      }
    }

    &__input {
      position: relative;
      margin: 3rem 0;
      z-index: 3;

      .button {
        position: absolute;
        right: 0.8rem;
        bottom: 0.8rem;
        background-color: $color-white;
        width: 2.4rem;
        height: 3.4rem;
        z-index: 2;

        svg {
          fill: var(--appTint);
        }
      }

      .geocoder {
        border: 1px solid $color-grey--light;

        .mapboxgl-ctrl-geocoder {
          box-shadow: none;
          width: 100%;
          max-width: 100%;

          &--icon-close {
            top: unset;
            width: 20px;
            height: 20px;
            margin-top: 8px;
            margin-right: 3px;
          }

          &--icon-search {
            display: none;
          }

          &--pin-right {
            button {
              right: 5rem;
            }
          }

          &--input {
            padding: 0 1.6rem;
            font-weight: $fontweight-medium;
            font-size: 1.5rem;
            line-height: 1.6rem;
            color: $color-dark;
            border-radius: 8px;
            font-family: 'Mukta', sans-serif;
            height: 50px;

            &::placeholder {
              color: $color-grey--dark;
            }
          }

          .suggestions {
            box-shadow: none;
            font-family: 'Mukta';
            border-radius: 0 0 8px 8px;
            font-size: 1.5rem;
            top: calc(5rem - 2px);
            border-top: 1px solid $color-grey--light;

            .active > a {
              background-color: unset;
            }

            .mapbox-gl-geocoder--no-results {
              padding: 12px;
            }
          }

          &--suggestion-title {
            color: $color-dark;
          }

          &--suggestion-address {
            color: $color-text;
          }
        }
      }
    }

    &__about {
      h3 {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $color-dark;
      }

      p {
        font-weight: $fontweight-regular;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $color-text;
      }

      .capitalize {
        text-transform: capitalize;
      }
    }
  }

  .button-fullscreen {
    width: 2.9rem;
    height: 2.9rem;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 2;
    padding: 0.7rem;
    background: $color-white;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;

    span svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &__map {
    position: relative;
    flex: 1;

    &__overlay {
      position: absolute;
      z-index: 3;
      background: rgba($color-dark, 0.75);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 4.6rem;
      text-align: center;
      padding-bottom: 6.6rem;
      cursor: pointer;

      img {
        width: 33%;
        max-width: 100px;
        margin-bottom: 1.6rem;
      }

      p {
        max-width: 250px;
        font-size: 2rem;
        line-height: 2.6rem;
        color: $color-white;
      }
    }

    &.fullscreen {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 3;

      .mapboxgl-map {
        border-radius: 0;
      }
    }

    &:not(.fullscreen) {
      .mapboxgl-ctrl-bottom-left,
      .mapboxgl-ctrl-bottom-right {
        bottom: 2rem;
      }

      .button-fullscreen {
        bottom: 3.6rem;
      }
    }

    mgl-map {
      width: 100%;
      height: 100%;
      transition: all 0.25s ease;

      .mapboxgl-marker:not(.marker) {
        display: none;
      }

      .marker {
        background-image: url('/assets/icons/marker.svg');
        background-size: 3.6rem 4.8rem;
        width: 3.6rem;
        height: 4.8rem;
        cursor: pointer;
        display: flex;
        justify-content: center;

        .icon {
          width: 3.2rem;
          height: 3.2rem;
          border-radius: 50%;
          margin-top: 0.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .address__map .mapboxgl-ctrl-bottom-right {
    bottom: 6rem !important;
  }

  .address__footer {
    border-radius: 0.8rem 0.8rem 0 0;
  }
}

.ios {
  .address__footer {
    padding: 1.6rem 1.6rem var(--safeAreaBottom) 1.6rem;
  }
}

.plt-desktop {
  .address {
    &__header {
      a:hover {
        text-decoration: underline;
      }
    }

    &__footer {
      &__button:hover {
        filter: brightness(90%);
      }
    }
  }
}
