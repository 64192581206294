app-map {
  height: 100%;
  width: 100%;
  display: block;

  .map {
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 1s opacity ease;

    &.loaded {
      opacity: 1;
    }
  }

  .error {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $color-border;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 1.4rem;
      color: $color-dark;
    }
  }
}
