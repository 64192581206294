.profile {
  &__content {
    padding: 2.4rem;
    text-align: center;

    &.logged-in p {
      text-align: left;
      display: flex;
      align-items: center;

      span {
        margin-right: 0.8rem;
        height: 2.4rem;
        width: 2.4rem;
      }
    }

    h1 {
      font-weight: $fontweight-bold;
      font-size: 2.2rem;
      line-height: 2.2rem;
      color: $color-dark;
      margin-bottom: 0.8rem;
    }

    p {
      font-weight: $fontweight-medium;
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: $color-dark;
    }

    &__buttons {
      width: 100%;
      margin-top: 4rem;

      button {
        margin: 0.5rem 0;
        width: 100%;
        text-align: center;
        justify-content: center;

        @include button;
        @include button--m;
        @include button--icon;
        @include button--flat($color-white, $color-dark);

        span {
          margin-right: 0.8rem;
        }
      }

      button:nth-child(2) {
        @include button--flat(var(--appTint), $color-white);
      }
    }

    .login {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0 auto;

      img {
        max-width: 6rem;
        height: 6rem;
        margin-bottom: 1.6rem;
      }

      p {
        padding: 0 1.6rem;
      }

      &__form {
        margin-top: 1.6rem;

        input {
          display: inline-block;
          padding: 0.8rem 1.6rem;
          background: $color-white;
          border-radius: 6px;
          font-size: 15px;
          line-height: 16px;
          color: $color-dark;
          width: 100%;
          height: 4.8rem;
          border: 1px solid $color-grey--light;
          margin-top: 1.6rem;
          margin-bottom: 0.8rem;

          &::placeholder {
            color: $color-text;
          }
        }

        .error {
          margin-top: 0.4rem;
          font-weight: $fontweight-medium;
          font-size: 1.4rem;
          line-height: 2.2rem;
          color: $color-red;
        }

        .button {
          @include button;
          @include button--m;

          width: 100%;
          margin-bottom: 0.8rem;
          height: 4.8rem;

          &--login {
            @include button--flat(var(--appTint), $color-white);
          }

          &--back {
            @include button--flat(transparent, $color-text);
          }
        }
      }
    }
  }
}

.plt-desktop {
  .profile {
    &__content {
      &__buttons {
        button:hover {
          cursor: pointer;
          background: $color-dark;
          color: $color-white;

          span {
            svg {
              fill: $color-white;
            }
          }
        }
      }
    }
  }
}
