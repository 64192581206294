/*------------------------------------*\
    App | Participation
\*------------------------------------*/
.participation {
  &__list {
    li {
      margin-bottom: 0.8rem;
    }
  }

  &__dashboard {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 4.8rem;

    > div {
      width: calc(100% / 3 - 0.4rem);
      min-width: calc(100% / 3 - 0.4rem);
      background: $color-white;
      box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
      border-radius: 1.2rem;
      margin-top: 0.8rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding: 2rem 1.6rem 2rem 1.6rem;

      > div {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        .inline-svg {
          height: 3.2rem;
          width: 3.2rem;
        }

        > span {
          position: absolute;
          right: -1rem;
          top: -0.8rem;
          width: 2rem;
          height: 2rem;
          background: $color-red;
          border-radius: 50%;
          font-weight: $fontweight-semibold;
          font-size: 1.4rem;
          line-height: 2rem;
          color: $color-white;
          text-align: center;
        }
      }

      > span {
        width: 100%;
        display: flex;
        text-align: center;
        flex-direction: column;
        display: block;
        font-weight: $fontweight-semibold;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $color-dark;
        margin-top: 1rem;
      }
    }

    .selected {
      background: $color-dark;
      color: $color-white;

      > span {
        color: $color-white;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 2.4rem;
      left: 50%;
      width: calc(100% - 11rem);
      height: 0.1rem;
      background: $color-border;
      transform: translateX(-50%);
    }
  }
}

.plt-desktop {
  .participation {
    &__dashboard {
      > div {
        transition: background-color 150ms linear;
      }

      > div:hover {
        cursor: pointer;
        background: $color-dark;
        color: $color-white;

        > span {
          color: $color-white;
        }
      }
    }
  }
}
