/*------------------------------------*\
    App | Conversation
\*------------------------------------*/
.conversation {
  &.ios {
    .conversation__chat {
      &--fullscreen {
        top: calc(6rem + 0.8 * var(--safeAreaTop));
      }
    }
  }

  background: $color-white;
  --ion-background-color: $color-white;

  &__inner {
    padding: 1.6rem 1.6rem 9.6rem 1.6rem;
  }

  &__head {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.2rem 0 3.2rem 0;

    &:after {
      content: '';
      position: absolute;
      bottom: 1.6rem;
      left: 50%;
      width: calc(100% - 11rem);
      height: 0.1rem;
      background: rgba($color-border, 0.5);
      transform: translateX(-50%);
    }
  }

  &__image {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    background: $color-grey;
    border-radius: 0.6rem;
    overflow: hidden;

    img {
      border-radius: 0.6rem;
    }
  }

  &__title {
    width: calc(100% - 9.6rem);
    min-width: calc(100% - 9.6rem);
    display: flex;
    flex-direction: column;
    padding-left: 0.8rem;

    > span {
      display: block;
      color: $color-dark;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        span {
          font-weight: $fontweight-medium;
          font-size: 1.4rem;
          line-height: 2rem;
          max-height: 2rem;
          color: $color-dark;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:first-child {
            margin-right: 0.4rem;
          }

          &:last-child:not(:first-child) {
            color: rgba($color-dark, 0.5);
          }
        }
      }

      &:last-child {
        font-weight: $fontweight-light;
        font-size: 1.2rem;
        line-height: 1.8rem;
        max-height: 1.8rem;
        white-space: nowrap;
      }
    }
  }

  &__content {
    &__meta {
      h1 {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $color-dark;
      }

      > span {
        display: block;

        &.date {
          font-weight: $fontweight-light;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: var(--appSupport);
        }

        &.status {
          font-weight: $fontweight-light;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: rgba($color-dark, 0.5);

          &.closed {
            color: $color-red;
          }
        }
      }
    }

    &__button {
      @include button;
      @include button--m;
      @include button--flat($color-black, $color-white);
      width: 18rem;
      margin: 2.4rem auto 0 auto;
    }
  }

  &__description {
    padding-top: 1.6rem;

    &__head {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: $color-dark;
    }

    &__content {
      position: relative;
      font-weight: $fontweight-light;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-dark;
      display: flex;
      flex-direction: column;
    }
  }

  &__chat {
    $self: &;
    padding-top: 1.6rem;

    &__head {
      > div:first-child {
        font-weight: $fontweight-bold;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $color-dark;
        display: flex;
        flex-direction: row;
        align-items: center;

        span:last-child {
          color: var(--appTint);
          padding-left: 0.4rem;
          display: flex;
          flex-direction: row;
          align-items: center;

          svg path {
            fill: var(--appTint);
          }
        }
      }

      > div:last-child {
        font-weight: $fontweight-regular;
        font-size: 1.2rem;
        line-height: 2rem;
        color: rgba($color-dark, 0.5);
      }
    }

    &--fullscreen {
      position: absolute;
      top: 6rem;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color-white;
      padding: 0 1.6rem 4rem 1.6rem;

      #{ $self }__head {
        > div:first-child {
          display: none;
        }

        > div:last-child {
          position: absolute;
          top: -4rem;
          right: 1.6rem;
          max-width: 10rem;
          text-align: right;
          font-weight: $fontweight-bold;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: var(--appTint);
        }
      }
    }
  }

  &__messages {
    > ul {
      display: flex;
      flex-direction: column;

      > li {
        position: relative;
        margin-top: 0.8rem;
        background: $color-grey--light;
        font-weight: $fontweight-light;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $color-dark;
        padding: 0.8rem 3.2rem 2.4rem 1.2rem;
        border-radius: 0.8rem;
        display: flex;
        flex-direction: column;
        max-width: 80%;
        min-width: 60%;

        .selectable {
          user-select: text;
        }

        &.support {
          &.hasImage {
            max-width: 100%;
            margin-right: 4rem;
            padding-right: 1.2rem;
          }
        }

        &.new {
          font-weight: $fontweight-medium;
          color: var(--appSupport);
          background: $color-white;
          border: 0.1rem dashed var(--appSupport);
          padding: 1.2rem;
          text-align: center;
          margin-left: auto;
          width: 60%;
        }

        &.device {
          margin-left: auto;
          background: var(--appSupport);
          color: $color-white;

          &.hasImage {
            max-width: 100%;
            margin-left: 4rem;
            padding-right: 1.2rem;
          }
        }

        span {
          display: block;

          &.title {
            font-weight: $fontweight-bold;
          }

          &.date {
            position: absolute;
            right: 0.8rem;
            bottom: 0.8rem;
            font-weight: $fontweight-regular;
            font-size: 1rem;
            line-height: 1.2rem;
            margin-left: auto;
            padding-top: 0.4rem;
            text-align: right;
          }
        }

        ul {
          padding-left: 2.5rem;

          > li {
            list-style-type: disc;
            padding-left: 0.5rem;
          }
        }

        ol {
          padding-left: 3rem;

          > li {
            list-style-type: decimal;
          }
        }

        .attachment {
          margin: 0.8rem 0 0.4rem 0;
          width: 100%;
          border-radius: 0.8rem;
          overflow: hidden;

          > div {
            width: 100%;
            height: 20rem;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }

  &__form {
    &__head {
      padding: 1.6rem 0 2.4rem 0;

      h1 {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $color-dark;
      }

      span {
        font-weight: $fontweight-bold;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: var(--appTint);
      }
    }

    form {
      .form-group {
        margin-bottom: 1.6rem;

        label {
          font-weight: $fontweight-medium;
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: $color-dark;
          display: block;
          margin-bottom: 0.4rem;

          span.required {
            font-weight: $fontweight-bold;
            color: $color-red;
          }
        }

        textarea {
          @include input;
          @include input--s;
          @include textarea;
        }

        &.attachment {
          .attachment__default,
          .attachment__added {
            > span {
              display: block;
              width: 100%;
              height: 4rem;
              border: 0.1rem dashed rgba($color-border, 0.5);
              border-radius: 0.8rem;
              color: $color-border;
              padding: 1.2rem 1.6rem;
              font-weight: $fontweight-medium;
              font-size: 1.4rem;
              line-height: 1.6rem;
            }

            input {
              display: none;
            }
          }

          .attachment__added {
            > span {
              display: flex;
              flex-direction: row;
              align-items: center;
              color: rgba($color-dark, 0.7);
              border: 0.1rem solid rgba($color-border, 0.5);
              padding: 0 1.6rem;

              span:last-child {
                margin-left: auto;
                width: 2.4rem;
                height: 2.4rem;

                svg path {
                  fill: $color-red;
                }
              }
            }
          }
        }
      }
    }

    &__button {
      @include button;
      @include button--m;
      @include button--flat(var(--appSupport), $color-white);
      width: 18rem;
      margin: 2.4rem auto 0 auto;
    }
  }

  &__closed {
    padding-top: 1.6rem;

    p {
      font-weight: $fontweight-regular;
      font-size: 1.2rem;
      line-height: 2.2rem;
      color: $color-dark;

      a {
        color: var(--appSupport);
      }
    }
  }

  &__provide-feedback {
    padding-top: 1.6rem;

    > span:first-child {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #212121;
    }

    .smiley-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 1.6rem -1.2rem;

      &__rating {
        width: 4.8rem;
        min-width: 4.8rem;
        height: 4.8rem;
        margin: 0 1.2rem;

        svg {
          width: 4.8rem;
          height: 4.8rem;

          svg g circle {
            fill: var(--appTint);
          }

          path {
            &:nth-child(1) {
              fill: var(--appSupport);
            }

            &:nth-child(2) {
              stroke: $color-white;
            }

            &:nth-child(3),
            &:nth-child(4) {
              fill: $color-white;
            }
          }
        }

        &--inactive {
          svg {
            path {
              &:nth-child(1) {
                fill: $color-border;
              }

              &:nth-child(2) {
                stroke: $color-dark;
              }

              &:nth-child(3),
              &:nth-child(4) {
                fill: $color-dark;
              }
            }
          }
        }
      }
    }
  }
}

.plt-desktop {
  .conversation {
    &__form {
      &__button:hover {
        filter: brightness(90%);
      }

      form {
        .form-group {
          &.attachment {
            .attachment__default:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    &__content {
      &__button:hover {
        cursor: pointer;
        color: $color-dark;
        background-color: $color-white;
        box-shadow: inset 0 0 0 0.1rem $color-dark;
      }
    }

    &__messages {
      ul {
        li.new:hover {
          cursor: pointer;
          color: $color-white;
          background-color: var(--appSupport);
        }
      }
    }

    &__chat {
      &__head {
        > div:first-child {
          span:last-child:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
