/*------------------------------------*\
    App | Onboarding
\*------------------------------------*/

.onboarding {
  padding: 0;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.6rem 2.4rem;
    height: 6.2rem;

    button {
      font-size: 1.4rem;
      font-weight: bold;
      height: 3rem;
      line-height: 3.2rem;
      color: var(--appTint);
      margin-left: auto;
      cursor: pointer;
    }
  }

  &__content {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 20rem;
    margin: 0 auto;

    img {
      margin: 4.8rem 0 6.4rem 0;
      max-width: 50%;

      &.logo {
        margin-bottom: 1.6rem;
        max-width: 6rem;
        height: 6rem;
      }
    }

    h1 {
      font-weight: $fontweight-bold;
      font-size: 2.2rem;
      line-height: 2.2rem;
      color: $color-dark;
      margin-bottom: 0.8rem;
    }

    p {
      font-weight: $fontweight-medium;
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: $color-dark;
      margin-bottom: 1.6rem;
    }

    &__button {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--flat(var(--appTint), $color-white);

      app-loader {
        margin-left: 0.8rem;
      }
    }
  }

  &__terms {
    position: absolute;
    bottom: 0.6rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 1.2rem;
    color: $color-grey--dark;
    cursor: pointer;
  }
}
