.update {
  background: $color-white;
  border-radius: 0 0 2.4rem 2.4rem;

  &__top,
  &__header,
  &__content,
  &__media,
  &__bottom {
    max-width: 76.8rem;
    margin: 0 auto;
  }

  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.6rem 1.6rem 0 1.6rem;
  }

  &__back {
    transition: transform 0.3s ease-in-out;
    position: relative;
    width: 3.2rem;
    min-width: 3.2rem;
    height: 3.2rem;

    &:before {
      content: '';
      position: absolute;
      top: 0.4rem;
      left: 0.4rem;
      width: 2.4rem;
      min-width: 2.4rem;
      height: 2.4rem;
      background-image: url('/assets/icons/arrow-left-thin.svg');
      background-size: 2.4rem;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $color-white;
      pointer-events: none;
    }

    ion-back-button {
      display: block;
      width: 3.2rem;
      min-width: 3.2rem;
      max-width: 3.2rem;
      height: 3.2rem;
      color: transparent;
    }
  }

  &__header {
    position: relative;
    background: $color-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.2rem 1.6rem 0.4rem 1.6rem;

    > div:first-child[tabindex='0'] {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__image {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    border-radius: 0.6rem;

    img {
      width: 4rem;
      height: 4rem;
      border-radius: 0.6rem;
      object-fit: cover;
    }
  }

  &__share {
    align-self: start;
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
  }

  &__info {
    width: calc(100% - 8rem);
    min-width: calc(100% - 8rem);
    padding-left: 1.2rem;
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-dark;
    }

    span:nth-child(2) {
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2rem;
      color: rgba($color-dark, 0.4);
    }

    span:last-child {
      font-weight: $fontweight-light;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: $color-dark;
    }
  }

  &__image {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    background: $color-grey;
    border-radius: 0.6rem;
    overflow: hidden;

    img {
      border-radius: 0.6rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 1.6rem;

    h2 {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;
      margin-bottom: 0.2rem;
    }

    > span {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: rgba($color-dark, 0.4);
    }

    > div {
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-dark;

      a,
      p,
      strong,
      u,
      em,
      li {
        font-weight: $fontweight-regular;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      ul,
      ol {
        padding-left: 3.2rem;
      }

      ul li {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }

      strong {
        font-weight: $fontweight-semibold;
      }

      a {
        color: var(--appTint);
      }
    }

    & &__note {
      color: var(--appTint);
      font-size: 1.4rem;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: $fontweight-bold;
      white-space: nowrap;
    }
  }

  &__events {
    padding: 0 1.6rem;
  }

  &__media {
    padding: 0 0.8rem;
  }

  &__bottom {
    padding: 1.2rem 1.6rem;
  }
}

.project__button__wrapper {
  position: absolute;
  left: 50%;

  &__button {
    left: -50%;
    /* Need a specific value to work */
    margin: 2.4rem auto;
    @include button;
    @include button--m;
    @include button--flat(var(--appTint), $color-white);
  }
}

.project-suggestion-wrapper {
  padding: 0.8rem;
}

.plt-desktop {
  .project__button__wrapper__button:hover,
  .project-button:hover {
    filter: brightness(90%);
  }

  .update {
    &__share:hover {
      cursor: pointer;

      svg {
        fill: var(--appTint);
      }
    }

    &__info:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    .read-more:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &__back:hover {
      cursor: pointer;
      transform: scale(1.5);
    }
  }
}
