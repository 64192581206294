/*------------------------------------*\
    App | Feed
\*------------------------------------*/
app-feed:has(nc-ngx-smart-banner) {
  padding-top: 84px;
}

nc-ngx-smart-banner {
  .smartbanner {
    font-family: 'Mukta', sans-serif !important;
    border-bottom: 1px solid $color-border;

    &--android {
      .smartbanner__button {
        background-color: var(--appTint) !important;
        border-radius: .6rem;
      }
    }

    &--ios {
      .smartbanner__button {
        color: var(--appTint) !important;
      }
    }
  }
}

.feed {
  .card {
    margin-bottom: 0.8rem;

    &__content.feed__project {
      p,
      b {
        font-size: 1.4rem;
      }
    }

    .feed__project__button {
      margin: 2.4rem auto;
      @include button;
      @include button--m;
      @include button--flat(var(--appTint), $color-white);
    }
  }
}

.notice {
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  padding: 20px 0 50px 0;
}
