/*------------------------------------*\
    App | Card, Summary
\*------------------------------------*/
.card--summary {
  $self: &;
  padding: 0;

  &__phone {
    > div {
      padding-right: 5px;
    }

    margin-top: 0.8rem;
    font-family: var(--font-semiBold);
    color: var(--appTint);
    text-decoration: none;
    font-weight: var(--font-weight-semiBold);
    cursor: pointer;
    display: flex;

    svg path {
      fill: var(--appTint);
    }
  }

  &__body {
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
  }

  &__inner.rating {
    .opinion__invalid {
      span {
        text-align: left;
        padding-top: 1rem;
      }
    }
  }

  &__inner {
    padding: 1.6rem;

    &.livestream {
      padding: 0.8rem;

      #{ $self }__head {
        padding: 0.8rem;
      }

      #{ $self }__description {
        padding: 0 0.8rem 1.2rem 0.8rem;
      }
    }

    &.contact {
      .card__head {
        padding: 0;
      }

      .card__image {
        min-width: 4rem;
        width: 4rem;
        height: 4rem;
      }
    }

    &.map {
      padding: 0.4rem;

      #{ $self }__head {
        padding: 1.2rem;
      }
    }

    .project-opinion__visual {
      > div {
        margin-top: 0.625rem;
      }
    }
  }

  &__map {
    padding: 0 0.8rem;
    height: calc((100vw - 3.2rem) / (16 / 10));
    width: 100%;

    .mapboxgl-map {
      border-radius: 10px;
    }
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.8rem;

    > div:first-child {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      h2 {
        font-weight: $fontweight-bold;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $color-dark;
      }

      > span {
        font-weight: $fontweight-bold;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $color-white;
        padding: 0.3rem 0.8rem;
        background: $color-red;
        border-radius: 0.6rem;
        margin-left: 0.8rem;
      }
    }

    > div:nth-child(2) {
      margin-left: auto;
    }
  }

  &__livestream {
    position: relative;
    width: 100%;
    height: 23.6rem;
    border-radius: 0.8rem;

    &__overlay {
      position: absolute;
      offset: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-dark, 0.6);
      border-radius: 0.8rem;
      text-align: center;
      z-index: 5;

      svg {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .live-stream,
    video {
      width: 100%;
      height: 100%;
      border-radius: 0.8rem;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.2rem;

    span.icon {
      width: 2rem;
      height: 2rem;
      margin-right: 0.8rem;

      svg {
        width: 2rem;
        height: 2rem;
        fill: $color-dark;
      }
    }

    h3 {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;
    }
  }

  &__description {
    font-weight: $fontweight-regular;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: $color-dark;

    a,
    p,
    strong,
    u,
    em,
    li {
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    ul,
    ol {
      padding-left: 3.2rem;
    }

    ul li {
      list-style-type: disc;
    }

    ol li {
      list-style-type: decimal;
    }

    strong {
      font-weight: $fontweight-semibold;
    }

    a {
      color: var(--appTint);
    }
  }

  &__link {
    display: inline-block;
    margin-top: 0.8rem;
    font-weight: $fontweight-regular;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--appTint);
  }

  &__progress {
    margin-top: 0.8rem;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        width: 6.6rem;
        height: 0.8rem;
        border-radius: 0.6rem;
        background: $color-grey--light;
        margin: 0 0.4rem 0.4rem 0;

        &:active {
          background: var(--appTint);
        }
      }
    }

    > span {
      display: block;
      text-align: right;
      font-weight: $fontweight-bold;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $color-dark;
    }

    + .card--summary__link {
      margin-top: 0;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;

    img {
      max-height: 250px;
    }
  }

  &__phases {
    display: flex;
    flex-direction: column;
    margin: 0.4rem 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;

      span.icon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.8rem;

        svg {
          width: 2rem;
          height: 2rem;
          fill: $color-dark;
        }
      }

      &.before {
        span.text {
          color: rgba($color-dark, 0.3);
        }

        span.icon svg path {
          fill: rgba($color-dark, 0.3);
        }
      }

      &.current {
        span:last-of-type {
          font-weight: 700;
          margin-left: 0.2rem;
          font-size: 1.3rem;
          color: $color-red;
        }
      }

      span.text {
        font-weight: $fontweight-regular;
        font-size: 1.3rem;
        line-height: 2rem;
        color: $color-dark;
        display: flex;
        flex-direction: column;
      }

      &:not(:last-child) {
        margin-bottom: 0.4rem;
      }
    }
  }

  &__button {
    @include button;
    @include button--m;
    @include button--icon;
    @include button--flat(var(--appSupport), $color-white);
    margin-top: 1.6rem;

    &:hover {
      filter: brightness(90%);
    }
  }

  &__children {
    li.card--child {
      padding: 1.6rem 0;

      &:first-child {
        padding-top: 0.8rem;
      }

      &:last-child {
        padding-bottom: 0.8rem;
      }

      &:not(:last-child) {
        border-bottom: 0.1rem solid rgba($color-border, 0.5);
      }
    }
  }

  &__documents {
    margin-top: 1.2rem;

    .document {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: $color-white;
      border: 0.1rem solid $color-grey--light;
      border-radius: 0.8rem;
      padding: 0.3rem;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      &__image,
      &__download {
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        border-radius: 0.6rem;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__content {
        width: calc(100% - 8rem);
        min-width: calc(100% - 8rem);
        padding: 0 1.2rem;
        display: flex;
        flex-direction: column;

        span:first-child {
          font-weight: $fontweight-semibold;
          font-size: 1.3rem;
          line-height: 1.3rem;
          color: $color-dark;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span:last-child {
          font-weight: $fontweight-semibold;
          font-size: 1.3rem;
          line-height: 1.3rem;
          color: rgba($color-dark, 0.4);
          display: block;
          margin-top: 0.4rem;
        }
      }

      &__download {
        margin-left: auto;
        padding: 1rem;
        background: $color-white;
      }
    }
  }

  &__map {
    border-radius: 0.8rem 0.8rem 1.2rem 1.2rem;
    overflow: hidden;
  }

  // Temporary addition
  &.report {
    .card__head {
      padding: 1.6rem 1.6rem 0 1.6rem;
    }

    #{ $self }__inner {
      padding-top: 0;
    }
  }

  &.highlighted {
    box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04),
      inset 0 0 0 0.1rem var(--appTint);
  }

  &__organization {
    display: flex;
    flex-direction: row;

    .card__content {
      padding-left: 1.6rem;
    }
  }
}

.plt-desktop {
  .card--summary {
    &__phone:hover {
      text-decoration: underline;
    }

    &__livestream {
      &__overlay:hover {
        cursor: pointer;
      }
    }

    &__link:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
