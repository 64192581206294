/*------------------------------------*\
    App | Breakpoints
\*------------------------------------*/
$screen--xs: 480px;
$screen--s: 640px;
$screen--m-max: 767px;
$screen--m-min: 768px;
$screen--l-max: 1023px;
$screen--l-min: 1024px;
$screen--xl: 1440px;

//@media screen and (max-width: $screen--m-max) {}
//@media screen and (min-width: $screen--m-min) and (max-width: $screen--l-max) {}
//@media screen and (min-width: $screen--l-min) and (max-width: $screen--xl) {}
//@media screen and (max-width: $screen-xl) {}

/*------------------------------------*\
    App | Fonts
\*------------------------------------*/
$fontweight-light: 300;
$fontweight-regular: normal;
$fontweight-medium: 500;
$fontweight-semibold: 600;
$fontweight-bold: bold;

/*------------------------------------*\
    App | Colors
\*------------------------------------*/
$color-white: #fff;
$color-black: #000;
$color-background: #f3f1ef;
$color-dark: #212121;
$color-text: #8f8f8f;
$color-grey--light: #e9e9e9;
$color-grey: rgba(#d9d9d9, 0.6);
$color-grey--dark: #9c9c9c;
$color-border: #cdcdcd;

$color-primary: #f5b049;
$color-blue: #3373ea;
$color-green: #49da16;
$color-red: #fc5447;
$color-red--light: #e1667d;
$color-purple: #a800f2;
$color-blue--light: #77ced8;

/*------------------------------------*\
    App | iOS Safe Area
\*------------------------------------*/
$fallbackSafeAreaTop: 0px;
$fallbackSafeAreaTopDesktop: 0px;
$fallbackSafeAreaBottomDesktop: 30px;

body {
  --safeAreaTop: constant(safe-area-inset-top);
  --safeAreaTop: env(safe-area-inset-top);
  --safeAreaBottom: constant(safe-area-inset-bottom);
  --safeAreaBottom: env(safe-area-inset-bottom);
  --screenHeight: 100vh; // javascript will overwrite this on mobile because of a mobile browser bug
}

html.plt-android body {
  --safeAreaTop: #{$fallbackSafeAreaTop};
  --safeAreaBottom: 0px;
}

html.plt-desktop body {
  --safeAreaTop: #{$fallbackSafeAreaTopDesktop};
  --safeAreaBottom: #{$fallbackSafeAreaBottomDesktop};
}
