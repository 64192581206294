/*------------------------------------*\
    App | Notifications
\*------------------------------------*/
.notifications {
  &__list {
    padding-bottom: 4rem;

    > div:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
}

.notification {
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  padding: 0.8rem;
  background: $color-white;
  box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
  border-radius: 0.8rem;

  &__image {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    overflow: hidden;
    border-radius: 0.6rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.7rem;
    }
  }

  &__text {
    width: calc(100% - 4rem);
    min-width: calc(100% - 4rem);
    padding: 0 3.2rem 0 0.8rem;

    font-weight: $fontweight-light;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $color-dark;

    > div {
      height: 2rem;
      overflow: hidden;
    }

    ul {
      padding-left: 2.2rem;
      margin: 0;

      > li {
        list-style-type: disc;
        padding-left: 0.5rem;
      }
    }

    ol {
      padding-left: 2.2rem;
      margin: 0;

      > li {
        list-style-type: decimal;
      }
    }

    > h2 {
      font-weight: $fontweight-semibold;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: $color-dark;
      margin-bottom: 0.2rem;
    }

    > p {
      strong {
        font-weight: $fontweight-bold;
      }
    }

    > span {
      display: block;
      font-weight: $fontweight-light;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: rgba($color-dark, 0.5);
      margin-top: 0.2rem;
    }
  }
}

.plt-desktop {
  .notification:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
}
