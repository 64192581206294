/*------------------------------------*\
    App | Card, Project
\*------------------------------------*/
.card {
  $self: &;

  &.card--project {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.2rem 1.6rem 1.2rem 1.6rem;
    transition: 260ms ease;

    > div:first-of-type[tabindex='0'] {
      width: calc(100% - 4rem);
      align-items: center;
      display: flex;
    }

    &.highlighted {
      background-color: var(--appTint);

      #{ $self }__content {
        > span {
          &:first-child {
            color: $color-white;
          }

          &[color='dark'] {
            color: $color-white;
          }

          &:last-child {
            color: $color-white;
          }
        }

        h2 {
          color: $color-white;
        }
      }

      #{ $self }__follow {
        box-shadow: inset 0 0 0 0.1rem $color-white;

        span {
          svg {
            path {
              fill: $color-white;
            }
          }
        }

        &.following {
          box-shadow: inset 0 0 0 0.1rem $color-white;

          svg path {
            fill: $color-white;
          }
        }
      }
    }

    #{ $self }__image {
      width: 4.8rem;
      min-width: 4.8rem;
      height: 4.8rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    #{ $self }__content {
      width: calc(100% - 4.8rem);
      min-width: calc(100% - 4.8rem);
      display: flex;
      flex-direction: column;
      padding: 0 1.6rem;

      h2 {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $color-dark;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > span {
        font-weight: $fontweight-regular;
        font-size: 1.3rem;
        line-height: 1.8rem;

        &:first-child {
          color: var(--appTint);
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: $fontweight-bold;
          white-space: nowrap;
        }

        &.main,
        &.child {
          span {
            color: $color-blue;

            svg {
              fill: $color-blue;
              margin-right: 0.4rem;
              font-weight: 300;
            }
          }
        }

        &[color='dark'] {
          color: $color-dark;
        }

        &:last-child {
          color: rgba($color-dark, 0.5);
        }
      }
    }

    #{ $self }__follow {
      width: 4rem;
      min-width: 4rem;
      height: 4rem;
      box-shadow: inset 0 0 0 0.1rem $color-grey;
      border-radius: 0.6rem;
      text-align: center;
      padding: 0.8rem;
      transition: 260ms ease;
      cursor: pointer;

      span {
        display: block;
        width: 2.4rem;
        height: 2.4rem;

        &:last-child {
          display: none;
        }

        svg {
          width: 2.4rem;
          height: 2.4rem;

          path {
            fill: $color-grey;
            transition: 260ms ease;
          }
        }
      }

      @media (hover: hover) {
        &:hover {
          box-shadow: inset 0 0 0 0.1rem var(--appTint);

          svg path {
            fill: var(--appTint);
          }
        }
      }

      &.disabled {
        pointer-events: none;

        span {
          display: none;
        }

        ion-spinner {
          height: 2.4rem;
          width: 2.4rem;
        }
      }

      &.following {
        box-shadow: inset 0 0 0 0.1rem var(--appTint);

        svg path {
          fill: var(--appTint);
        }

        span {
          &:first-of-type {
            display: none;
          }

          &:last-of-type {
            display: block;
          }
        }
      }
    }
  }
}

.plt-desktop {
  .card {
    &.card--project {
      transition: background-color 300ms linear;
    }

    &.card--project:hover {
      background-color: var(--appTint);

      .card__content {
        span,
        h2 {
          color: $color-white;
        }
      }

      .card__follow {
        box-shadow: inset 0 0 0 0.1rem $color-white;

        span {
          svg {
            path {
              fill: $color-white;
            }
          }
        }

        &.following {
          box-shadow: inset 0 0 0 0.1rem $color-white;

          svg path {
            fill: $color-white;
          }
        }
      }
    }
  }
}
