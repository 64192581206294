/*------------------------------------*\
    App | Settings
\*------------------------------------*/
.settings {
  &__main {
    padding: 0 0.8rem 2.4rem 0.8rem;

    > div {
      position: relative;
      width: 100%;
      padding: 1.6rem 5.6rem 1.6rem 1.6rem;
      background: $color-white;
      box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
      border-radius: 0.8rem;
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;

      &:not(:last-child) {
        margin-bottom: 1.6rem;
      }

      &:after {
        content: '';
        position: absolute;
        top: 1.5rem;
        right: 2.4rem;
        width: 2.4rem;
        height: 2.4rem;
        background-image: url('/assets/icons/arrow-right--dark.svg');
        background-size: 2.4rem;
        background-repeat: no-repeat;
      }
    }
  }

  &__line {
    width: calc(100% - 11rem);
    height: 0.1rem;
    margin: 0 auto;
    background: $color-border;
  }

  &__sub {
    padding: 2.4rem;

    > div {
      font-weight: $fontweight-semibold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: $color-text;
      width: fit-content;

      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }

      &:nth-child(-n + 3) {
        font-weight: $fontweight-bold;
      }
    }
  }
}

/*------------------------------------*\
    App | Projects
\*------------------------------------*/
.projects {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}

/*------------------------------------*\
    App | Permissions & Preferences
\*------------------------------------*/
.permissions,
.preferences {
  transition: transform 0.3s ease-in-out;
  padding: 0 0.8rem 0.8rem 0.8rem;

  &__box {
    background: $color-white;
    box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
    border-radius: 1.6rem;
    margin-bottom: 0.8rem;
    padding: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__content {
      width: calc(100% - 10.4rem);
      min-width: calc(100% - 10.4rem);

      > h2 {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $color-dark;
      }

      > p {
        font-weight: $fontweight-light;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $color-dark;
      }

      ul {
        margin-top: 1.6rem;

        li {
          h3 {
            font-weight: $fontweight-medium;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: $color-dark;
          }

          p {
            font-weight: $fontweight-regular;
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: rgba($color-dark, 0.5);
          }
        }
      }
    }

    &__setting {
      margin-left: auto;
      height: 4rem;
      padding: 1rem;

      span {
        font-weight: $fontweight-bold;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $color-text;
      }
    }

    &__toggle {
      margin-left: auto;

      ion-toggle:focus {
        opacity: 0.5;
      }
    }

    &__icon {
      width: 4rem;
      min-width: 4rem;
      height: 4rem;
      text-align: center;
      padding: 1rem;
    }
  }
}

/*------------------------------------*\
    App | About App
\*------------------------------------*/

.about-app {
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem;

  p {
    color: $color-dark;
    font-size: 1.6rem;
    font-weight: $fontweight-regular;
    padding: 0.8rem 0;

    li {
      list-style-type: decimal;
    }
  }
}

.plt-desktop {
  .settings {
    &__main {
      > div:hover {
        &::after {
          transform: scale(1.5);
        }

        cursor: pointer;
        transform: scale(1.025);
      }
    }

    &__sub {
      > div:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .preferences {
    &__box:not(:first-child):hover {
      cursor: pointer;
      transform: scale(1.01);
    }
  }
}
