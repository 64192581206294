app-translation-button {
  order: -1;

  .translation-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.8rem;
    text-decoration: none;
    background: none;

    span:first-child {
      margin-right: 0.4rem;
      width: 1.6rem;
      height: 1.6rem;
      display: block;

      svg {
        display: block;
      }
    }

    span:nth-child(2) {
      font-weight: $fontweight-regular;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: rgba($color-dark, 0.5);
    }
  }

  ion-spinner {
    height: 1.6rem;
  }
}

.plt-desktop {
  app-translation-button:hover {
    text-decoration: underline;
  }
}
