/*------------------------------------*\
    App | Opinion
\*------------------------------------*/
.opinion {
  overflow-y: scroll;

  &__head,
  &__content {
    width: 100%;
    max-width: 76.8rem;
    margin: 0 auto;
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.6rem 1.6rem 1.2rem 1.6rem;
  }

  &__content {
    padding: 0 1.6rem 12rem 1.6rem;
  }

  &__back {
    width: 3.2rem;
    min-width: 3.2rem;
    height: 3.2rem;
    padding: 0.4rem;
  }

  &__translation {
    margin-left: auto;
  }

  &__intro {
    padding-bottom: 2.4rem;

    h1 {
      font-weight: $fontweight-bold;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: $color-dark;
    }

    p {
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: rgba($color-dark, 0.5);
    }
  }

  &__button {
    text-align: center;
    margin-top: 1.6rem;
  }

  &__invalid {
    span {
      svg {
        width: 100%;
      }
    }
  }

  &__options {
    padding-top: 1.2rem;

    &__item {
      display: flex;
      flex-direction: column;

      h2 {
        font-weight: $fontweight-bold;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: $color-dark;
      }

      app-smiley-input {
        width: 100%;
        align-self: center;
      }

      .smiley-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.6rem;

        &__rating {
          width: 4.8rem;
          min-width: 4.8rem;
          height: 4.8rem;

          svg {
            width: 4.8rem;
            height: 4.8rem;

            svg g circle {
              fill: var(--appTint);
            }

            path {
              &:nth-child(1) {
                fill: var(--appSupport);
              }

              &:nth-child(2) {
                stroke: $color-white;
              }

              &:nth-child(3),
              &:nth-child(4) {
                fill: $color-white;
              }
            }
          }

          &--inactive {
            svg {
              path {
                &:nth-child(1) {
                  fill: $color-border;
                }

                &:nth-child(2) {
                  stroke: $color-dark;
                }

                &:nth-child(3),
                &:nth-child(4) {
                  fill: $color-dark;
                }
              }
            }
          }
        }
      }

      input {
        margin-top: 1.6rem;
        @include input;
        @include input--s;
      }
    }
  }

  &__button {
    > button {
      @include button--outlined(
        var(--appSupport),
        var(--appSupport),
        $color-white
      );
      margin-top: 0;
    }

    .icon {
      svg {
        path {
          fill: var(--appSupport);
        }
      }
    }

    > a:hover {
      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }

  &__bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6.4rem;
    background: $color-white;
    box-shadow: 0 -0.2rem 0.4rem rgba($color-black, 0.06);
    text-align: center;
    padding: 1.2rem;

    button {
      @include button;
      @include button--m;
      @include button--flat(var(--appSupport), $color-white);
      margin: 0 auto;
      padding: 0 2.4rem;

      &.isLoading {
        color: $color-white;
        background: var(--appSupport);
        box-shadow: none;
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          z-index: 2;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--appSupport);
          border-radius: 0.8rem;
          box-shadow: inset 0 0 0 0.1rem var(--appSupport);
        }

        ion-spinner {
          position: absolute;
          z-index: 5;
          top: 50%;
          left: 50%;
          right: 0;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

@media (min-width: $screen--s) {
  .opinion {
    &__options {
      &__item {
        .smiley-input {
          justify-content: space-evenly;
        }
      }
    }
  }
}

.plt-desktop {
  .smiley-input {
    &__rating:hover {
      cursor: pointer;
      svg {
        svg g circle {
          fill: var(--appTint);
        }

        path {
          &:nth-child(1) {
            fill: var(--appSupport);
          }

          &:nth-child(2) {
            stroke: $color-white;
          }

          &:nth-child(3),
          &:nth-child(4) {
            fill: $color-white;
          }
        }
      }
    }
  }
}
