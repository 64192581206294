.social-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &.inactive {
    li svg {
      fill: $color-grey--dark !important;
    }
  }

  button {
    height: 3.6rem;
    min-width: 3.6rem;
    position: relative;
    padding: 0.8rem;
    overflow: hidden;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    color: $color-grey--dark;
    transition: 0.25s all ease;

    svg {
      transition: transform 0.3s ease-in-out;
    }

    &:not(.active) {
      background: none !important;
      justify-content: center;

      &:has(.count.show) {
        min-width: 4.8rem;
      }
    }

    &.active {
      border: none !important;
      color: $color-white !important;
      margin: 0 6px;
      min-width: 4.8rem;
      margin-right: 0.8rem;

      &:first-child {
        margin-left: 0;
      }

      svg {
        fill: $color-white !important;
      }
    }

    svg {
      width: 2rem;
      height: 2rem;
    }

    &.like {
      background-color: #3373ea;

      .ripple {
        color: #3373ea;
      }

      svg {
        fill: #3373ea;
      }
    }

    &.awesome {
      background-color: #f5b049;

      .ripple {
        color: #f5b049;
      }

      svg {
        fill: #f5b049;
      }
    }

    &.idea {
      background-color: $color-green;

      .ripple {
        color: $color-green;
      }

      svg {
        fill: $color-green;
      }
    }

    &.worried {
      background-color: $color-purple;

      .ripple {
        color: $color-purple;
      }

      svg {
        height: 1.6rem;
        margin-top: 0.2rem;
        fill: $color-purple;
      }
    }

    &.dislike {
      background-color: $color-red;

      .ripple {
        color: $color-red;
      }

      svg {
        fill: $color-red;
      }
    }

    > div {
      font-weight: $fontweight-regular;
      font-size: 1.2rem;
      height: 2rem;
      display: block;
      color: $color-grey--dark;
    }

    > span {
      margin-left: 0.4rem;
      display: none;
      min-width: 0.8rem;
      font-size: 1.2rem;
    }

    > span.show {
      display: block;
    }
  }
}

.plt-desktop {
  .social-buttons {
    button:not(.close):not(.count):hover {
      svg {
        transform: scale(1.25);
      }
    }
  }
}
