/*------------------------------------*\
    App | Card, Form
\*------------------------------------*/
.card--form {
  padding: 1.6rem;

  app-form-group {
    > div {
      margin-bottom: 1.6rem;

      &.form-group--error {
        input,
        textarea {
          border-color: $color-red;
        }
      }

      label {
        font-weight: $fontweight-medium;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $color-dark;
        display: block;
        margin-bottom: 0.4rem;

        &.required {
          &:after {
            content: ' *';
            color: $color-red;
          }
        }
      }

      input,
      textarea {
        @include input;
        @include input--s;
      }

      textarea {
        @include textarea;

        + .errors {
          margin-top: 0;
        }
      }

      .errors {
        margin-top: 0.4rem;
        font-weight: $fontweight-medium;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $color-red;
      }
    }
  }

  button {
    @include button;
    @include button--m;
    @include button--flat($color-dark, $color-white);
    width: 100%;

    &:disabled {
      color: transparent;
      background: transparent;
      box-shadow: none;

      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-white;
        border-radius: 0.8rem;
        box-shadow: inset 0 0 0 0.1rem rgba($color-dark, 0.2);
      }

      ion-spinner {
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 50%;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.plt-desktop {
  .card--form {
    app-form-group {
      .file-input__media:hover {
        cursor: pointer;
      }
    }

    button:hover {
      color: $color-dark;
      background-color: $color-white;
      box-shadow: inset 0 0 0 0.1rem $color-dark;
    }
  }
}
