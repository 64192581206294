/*------------------------------------*\
    App | Card, Project Suggestion
\*------------------------------------*/

.project-suggestion {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    text-align: left;
    position: relative;
    flex-grow: 1;

    > div {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__type {
    flex-direction: row;

    > span {
      color: var(--appTint);
      padding-right: 0.4rem;

      > svg {
        fill: var(--appTint);
      }
    }
  }

  app-card-project-suggestion {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
