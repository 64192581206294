@font-face {
  font-family: 'Mukta';
  src: url('../fonts/Mukta/Mukta-Light.woff2') format('woff2'),
    url('../fonts/Mukta/Mukta-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mukta';
  src: url('../fonts/Mukta/Mukta-Medium.woff2') format('woff2'),
    url('../fonts/Mukta/Mukta-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mukta';
  src: url('../fonts/Mukta/Mukta-Regular.woff2') format('woff2'),
    url('../fonts/Mukta/Mukta-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mukta';
  src: url('../fonts/Mukta/Mukta-SemiBold.woff2') format('woff2'),
    url('../fonts/Mukta/Mukta-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mukta';
  src: url('../fonts/Mukta/Mukta-Bold.woff2') format('woff2'),
    url('../fonts/Mukta/Mukta-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mukta';
  src: url('../fonts/Mukta/Mukta-ExtraLight.woff2') format('woff2'),
    url('../fonts/Mukta/Mukta-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mukta';
  src: url('../fonts/Mukta/Mukta-ExtraBold.woff2') format('woff2'),
    url('../fonts/Mukta/Mukta-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
