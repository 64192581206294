/*------------------------------------*\
    App | Project
\*------------------------------------*/
.project {
  font-family: 'Mukta', sans-serif;

  &__header,
  &__content {
    max-width: 76.8rem;
    margin: 0 auto;
  }

  &__header {
    position: relative;
    z-index: 10;
    background: $color-white;
    border-radius: 0 0 1.2rem 1.2rem;

    &.dark {
      background-color: $color-dark;

      .project__header__cover {
        background-color: $color-dark;
      }

      .project__info {
        h1,
        span,
        li {
          color: $color-white;
        }
      }

      .project__about {
        a {
          color: $color-white;
        }
      }
    }

    &__cover {
      position: relative;
      width: 100%;
      height: 15rem;
      background-color: $color-grey--light;

      > div:first-child {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        border-radius: 0 0 0.8rem 0.8rem;
      }

      > div:last-child {
        position: absolute;
        top: 0.8rem;
        left: 0.8rem;

        .back-button {
          background: $color-white;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.2rem 1.2rem 0 1.2rem;
    }

    &__action {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 1.2rem 1.2rem 0.4rem 1.2rem;
      border-bottom: 0.1rem solid $color-background;

      > div {
        margin-bottom: 0.8rem;

        &:not(:last-child) {
          margin-right: 0.8rem;
        }
      }
    }
  }

  &__image {
    width: 4.8rem;
    min-width: 4.8rem;
    height: 4.8rem;
    border-radius: 0.6rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.6rem;
      object-fit: cover;
    }
  }

  &__about {
    width: 3.2rem;
    position: absolute;
    right: 0.8rem;

    a {
      color: $color-dark;
    }
  }

  &__info {
    width: calc(100% - 4.8rem);
    min-width: calc(100% - 4.8rem);
    padding-left: 1.2rem;

    h1 {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;
    }

    > span.location {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: $fontweight-bold;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: var(--appTint);
      cursor: pointer;

      span.icon {
        width: 1.6rem;
        height: 1.6rem;
        margin-left: 0.4rem;

        svg path {
          fill: var(--appTint);
        }
      }
    }

    > span:not(.location) {
      font-weight: $fontweight-light;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: $color-dark;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        position: relative;
        font-weight: $fontweight-light;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: rgba($color-dark, 0.5);

        &:not(:last-child) {
          margin-right: 1.4rem;

          &:after {
            content: '';
            position: absolute;
            top: 0.8rem;
            right: -0.8rem;
            width: 0.2rem;
            height: 0.2rem;
            background: rgba($color-dark, 0.5);
            border-radius: 50%;
          }
        }
      }
    }
  }

  &__follow,
  &__unfollow,
  &__live {
    @include button;
    @include button--s;
    @include button--icon;

    span.icon {
      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }

  &__follow {
    @include button--flat(var(--appTint), $color-white);
  }

  &__unfollow {
    @include button--outlined($color-border, $color-border, $color-white);
  }

  &__live {
    @include button--flat($color-red, $color-white);

    span.icon {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__share {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.4rem;
    background: rgba($color-grey--light, 0.5);
    border-radius: 0.6rem;
  }

  &__content {
    padding: 0.8rem;
    min-height: calc(100% - 31.7rem);

    .card {
      margin-bottom: 0.8rem;
    }

    .conversations {
      padding: 0.8rem 0;
    }

    &__filter {
      display: flex;
      align-items: center;
      pointer-events: all;
      cursor: pointer;
      justify-content: center;
      padding: 0.8rem 0 1.6rem 0;

      span {
        font-size: 1.4rem;
        font-weight: $fontweight-bold;
        height: 3rem;
        line-height: 3rem;

        &:last-child {
          padding-left: 0.8rem;
        }

        svg {
          height: 100%;
        }
      }
    }
  }
}

video {
  width: 100%;
  height: 200px;
  background-color: black;
}

app-project-smart-reporting {
  mgl-map {
    width: 100%;
    height: 50%;

    .marker {
      background-image: url('/assets/icons/marker.svg');
      background-size: 3.6rem 4.8rem;
      width: 3.6rem;
      height: 4.8rem;
      cursor: pointer;
    }
  }
}

.plt-desktop {
  .project {
    &__live:hover {
      filter: brightness(90%);
    }

    &__share:hover {
      cursor: pointer;

      svg {
        fill: var(--appTint);
      }
    }
  }
}
