/*------------------------------------*\
    App | Modal
\*------------------------------------*/

ion-modal.media-slider-modal {
  --height: calc(100% - var(--safeAreaTop));

  &::part(backdrop) {
    --backdrop-opacity: 1;
  }

  &::part(content) {
    --background: transparent;
  }

  ion-toolbar {
    --background: none;

    .dismiss {
      background-color: transparent;
      color: $color-white;

      > svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .share {
      margin-right: 1.6rem;
      background-color: transparent;
      color: $color-white;

      > svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  .ion-page {
    ion-content {
      --background: none;
    }

    .dismiss {
      position: absolute;
      top: calc(1.6rem + var(--safeAreaTop));
      right: 1.6rem;
      background-color: transparent;
      color: $color-white;

      > svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .share {
      position: absolute;
      top: calc(1.6rem + var(--safeAreaTop));
      right: 5.4rem;
      background-color: transparent;
      color: $color-white;

      > svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  .media-slider {
    width: 100%;
    height: 100%;
    background: none;
    position: unset;
    margin-top: unset;
    overflow: unset;

    swiper-container {
      height: 100%;

      swiper-slide {
        height: 100%;
        width: 100%;
      }

      img,
      video,
      iframe {
        object-fit: contain;
      }
    }

    &__pagination {
      position: absolute;
      z-index: 10;
      bottom: calc(2rem + var(--safeAreaBottom));
      right: 1rem;
      font-weight: $fontweight-bold;
      font-size: 1.2rem;
      color: $color-white;
      background-color: rgba($color-black, 0.5);
      padding: 2px 15px;
      border-radius: 20px;
    }

    &__description {
      position: absolute;
      z-index: 10;
      bottom: calc(2rem + var(--safeAreaBottom));
      left: 50%;
      transform: translateX(-50%);
      font-weight: $fontweight-bold;
      font-size: 1.2rem;
      color: $color-white;
      background-color: rgba($color-black, 0.5);
      padding: 2px 15px;
      border-radius: 20px;
    }
  }
}

ion-modal.widget {
  app-project-about-us {
    overflow-y: auto;
    background-color: #f3f1ef;
    padding: 0.8rem;
    justify-content: start;

    .card {
      margin-bottom: 0.8rem;
    }

    .close {
      align-self: end;
      margin-bottom: 0.8rem;
    }
  }
}
