/*------------------------------------*\
    App | Planning
\*------------------------------------*/
.planning {
  padding-top: 0.8rem;

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.8rem;
    margin-bottom: 1.6rem;

    span.icon {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      margin-right: 0.8rem;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    h2 {
      font-weight: $fontweight-medium;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: $color-dark;
    }
  }

  &__items {
    $self: &;
    padding-left: 3.6rem;

    > li {
      position: relative;
      transition: 0.5s ease max-height;

      > button {
        @include button;
        @include button--dashed($color-dark, $color-white);
        @include button--m;
        @include button--icon;
        width: 100%;
        justify-content: center;
        font-size: 1.4rem;

        &:before {
          content: '';
          position: absolute;
          top: 1.8rem;
          left: -2.5rem;
          width: 1rem;
          height: 1rem;
          background: $color-white;
          border: 0.2rem solid $color-dark;
          border-radius: 50%;
        }

        span.icon {
          margin-right: 0.4rem;
          margin-bottom: 0.2rem;

          svg {
            fill: $color-dark;
          }
        }

        &:hover {
          span.icon svg {
            fill: $color-white;
          }
        }
      }

      &.hide {
        display: none;
      }

      #{ $self }__events {
        padding-left: 2.6rem;

        > button {
          @include button;
          @include button--dashed($color-dark, $color-white);
          @include button--m;
          @include button--icon;
          width: 100%;
          justify-content: center;
          font-size: 1.4rem;
          display: block;
          margin-top: 1.6rem;

          &:before {
            content: '';
            position: absolute;
            top: 1.8rem;
            left: -2rem;
            width: 1rem;
            height: 1rem;
            background: $color-white;
            border: 0.2rem solid $color-dark;
            border-radius: 50%;
          }

          &:after {
            content: '';
            position: absolute;
            top: -1.4rem;
            left: -1.6rem;
            width: 0.2rem;
            height: 3rem;
            border: 0.1rem dashed $color-border;
          }

          span.icon {
            margin-right: 0.4rem;
            margin-bottom: 0.2rem;

            svg {
              fill: $color-dark;
            }
          }

          &:hover {
            span.icon svg {
              fill: $color-white;
            }
          }
        }

        button + app-event {
          &:first-of-type .event {
            &:after {
              top: -2.6rem;
              height: 3.8rem;
            }
          }
        }

        > app-event {
          &:first-of-type .event {
            &:after {
              content: '';
              position: absolute;
              top: -1.6rem;
              left: -1.6rem;
              width: 0.2rem;
              height: 2.8rem;
              border: 0.1rem dashed $color-border;
            }
          }

          .event {
            &:before {
              left: -2rem;
              top: 1.4rem;
            }
          }
        }
      }

      app-event {
        position: relative;

        .event {
          &:before {
            content: '';
            position: absolute;
            top: 1.6rem;
            left: -2.5rem;
            width: 1rem;
            height: 1rem;
            background: $color-white;
            border: 0.2rem solid $color-dark;
            border-radius: 50%;
          }
        }

        &:not(:last-child) {
          margin-bottom: 1.6rem;

          &:before {
            content: '';
            position: absolute;
            top: 2.2rem;
            left: -1.5rem;
            width: 0.2rem;
            height: calc(100% + 1.8rem);
            border: 0.1rem dashed $color-border;
          }
        }
      }

      &:first-child {
        &:after {
          content: '';
          position: absolute;
          top: -1rem;
          left: -2rem;
          width: 0.2rem;
          height: 2.8rem;
          border: 0.1rem dashed $color-border;
        }
      }

      &:not(:last-child) {
        margin-bottom: 1.6rem;

        &:before {
          content: '';
          position: absolute;
          top: 1.8rem;
          left: -2rem;
          width: 0.2rem;
          height: calc(100% + 1.6rem);
          border: 0.1rem dashed $color-border;
        }
      }

      &.second {
        #{ $self }__box,
        .event {
          border-color: $color-blue;

          &:before,
          &:after,
          .event:before {
            border-color: $color-blue !important;
          }
        }

        #{ $self }__events {
          app-event:before {
            border-color: $color-blue;
          }
        }
      }

      &.third {
        #{ $self }__box,
        .event {
          border-color: $color-red--light;

          &:before,
          &:after .event:before {
            border-color: $color-red--light !important;
          }
        }

        #{ $self }__events {
          app-event:before {
            border-color: $color-red--light;
          }
        }
      }
    }

    &__box {
      position: relative;
      background: $color-white;
      border: 0.2rem solid $color-dark;
      box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
      border-radius: 1.6rem;
      padding: 1.2rem 1.6rem;

      &:focus {
        transform: scale(1.025);
      }

      &:before {
        content: '';
        position: absolute;
        top: 1.6rem;
        left: -2.6rem;
        width: 1rem;
        height: 1rem;
        background: $color-white;
        border: 0.2rem solid $color-dark;
        border-radius: 50%;
      }

      > h3 {
        font-weight: $fontweight-semibold;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: $color-dark;
      }

      > span {
        display: block;
        font-weight: $fontweight-light;
        font-size: 1.2rem;
        line-height: 2rem;
        color: #3373ea;
      }

      > div {
        font-weight: $fontweight-regular;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $color-dark;

        &.current {
          margin: 0.4rem 0.2rem;
          color: $color-blue;
          font-size: 1.2rem;
          display: flex;
          align-items: center;
          font-weight: $fontweight-semibold;

          span {
            line-height: 1.6rem;
            font-size: 1.2rem;

            &:last-child {
              margin-left: 0.4rem;
            }

            svg {
              fill: $color-blue;
              height: 1.6rem;
              width: 1.6rem;
            }
          }
        }

        &.text {
          display: flex;
          flex-direction: column;

          app-translation-button {
            order: 0;
            margin-top: 0.4rem;

            .translation-button {
              background: none;
              text-decoration: none;
              margin-bottom: 0;
            }
          }
        }

        a,
        p,
        strong,
        u,
        em,
        li {
          font-weight: $fontweight-regular;
          font-size: 1.4rem;
          line-height: 2.2rem;
        }

        ul,
        ol {
          padding-left: 3.2rem;
        }

        ul li {
          list-style-type: disc;
        }

        ol li {
          list-style-type: decimal;
        }

        strong {
          font-weight: $fontweight-semibold;
        }

        a {
          color: var(--appTint);
        }
      }
    }

    &__current {
      width: 100%;
      margin: 0;
      padding: 0.8rem 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &:before {
        top: 0 !important;
        height: calc(100% + 3.2rem) !important;
      }

      span {
        &:nth-child(1),
        &:nth-child(3) {
          width: 4.8rem;
          height: 0.1rem;
          background: $color-red;
        }

        &:nth-child(2) {
          font-weight: $fontweight-bold;
          font-size: 1.4rem;
          line-height: 2rem;
          color: $color-red;
          margin: 0 0.8rem;
        }
      }
    }

    &.lastBeforeCurrent {
      &:before {
        display: none;
      }
    }
  }
}

/*------------------------------------*\
    App | Planning, Detail
\*------------------------------------*/
.planning-detail {
  overflow-y: scroll;

  &__head,
  &__content {
    width: 100%;
    max-width: 76.8rem;
    margin: 0 auto;
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.6rem 1.6rem 1.2rem 1.6rem;
  }

  &__content {
    padding: 0 0.8rem 12rem 0.8rem;
  }

  &__back {
    width: 3.2rem;
    min-width: 3.2rem;
    height: 3.2rem;
    padding: 0.4rem;
  }

  &__translation {
    margin-left: auto;
  }

  &__line {
    width: calc(100% - 6.4rem);
    height: 0.1rem;
    margin: 1.6rem auto;
    background: rgba($color-border, 0.5);
  }

  .card__head,
  &__inner {
    padding: 0 0.8rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;

    > h1 {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;
    }

    > span {
      display: block;
      font-weight: $fontweight-light;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: var(--appSupport);

      &.type {
        color: $color-red;
      }
    }

    > div {
      padding: 0.8rem 0 1.6rem 0;
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: $color-dark;

      a,
      p,
      strong,
      u,
      em,
      li {
        font-weight: $fontweight-regular;
        font-size: 1.4rem;
        line-height: 2.2rem;
      }

      ul,
      ol {
        padding-left: 3.2rem;
      }

      ul li {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }

      strong {
        font-weight: $fontweight-semibold;
      }

      a {
        color: var(--appTint);
      }
    }
  }
}

.plt-desktop {
  .planning__items__box:hover {
    cursor: pointer;
    transform: scale(1.025);
  }
}
