app-events {
  display: flex;
  flex-direction: column;

  .read-more {
    font-weight: $fontweight-bold;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: var(--appTint);
    background: none;

    &.sticky {
      right: 24px;
      bottom: 8px;

      svg {
        width: 2.4rem;
        height: 2.4rem;
        fill: $color-dark;
      }
    }
  }

  .sticky-pin {
    background-color: var(--appTint);
    border-radius: 0px 1.6rem 0px 1.6rem;
    position: absolute;
    top: -2px;
    right: -2px;
    padding: 0.8rem;
    width: 4rem;
    height: 4rem;

    svg {
      height: 2.4rem;
      width: 2.4rem;
      fill: $color-white;
    }
  }

  .sticky-read-more {
    background: none;
    position: absolute;
    width: 4rem;
    height: 4rem;
    padding: 0.8rem;
    bottom: 0;
    right: 0;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      fill: $color-dark;
    }
  }

  .sticky-stacked {
    > div {
      height: 1.6rem;
      border: 1px solid var(--appTint);
      border-radius: 1.6rem;
      position: absolute;
      background-color: $color-white;

      &:first-child {
        z-index: -1;
        width: 96%;
        left: 2%;
        bottom: -0.8rem;
      }

      &:last-child {
        z-index: -2;
        width: 92%;
        left: 4%;
        bottom: -1.6rem;
      }
    }
  }
}

app-event {
  &:not(:last-of-type) {
    margin-bottom: 1.6rem;
  }

  .event {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-right: 12.4rem;

    app-translation-button {
      order: 1;

      .translation-button {
        background: none;
        text-decoration: none;
        margin-top: 0.4rem;
        margin-bottom: 0;
      }
    }

    &.standalone {
      background: $color-white;
      border: 0.1rem solid $color-dark;
      box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
      border-radius: 1.6rem;
      padding: 1.2rem 12.4rem 1.2rem 1.6rem;
      margin: 1.6rem 0;

      .active {
        right: 1.6rem;
      }
    }

    > span {
      font-weight: $fontweight-semibold;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: $color-dark;
    }

    &.upcoming .active {
      color: var(--appTint);
      border: 0.1rem solid var(--appTint);
    }

    &.current .active {
      background-color: $color-red;

      &.soon {
        animation-name: pulse;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    }

    @keyframes pulse {
      from {
        box-shadow: 0px 0px 4px 4px rgba($color-border, 0.8);
      }
      to {
        box-shadow: none;
      }
    }

    .active {
      position: absolute;
      right: 0rem;
      top: 50%;
      min-width: 100px;
      text-align: center;
      transform: translateY(-50%);
      border-radius: 0.6rem;
      padding: 0.4rem 0.8rem;
      color: $color-white;
      font-weight: $fontweight-semibold;
      font-size: 1.2rem;
    }

    &__content {
      p {
        font-weight: $fontweight-regular;
        font-size: 1.2rem;
        line-height: 2rem;
        color: $color-blue;

        span {
          font-weight: $fontweight-bold;
          line-height: 1.6rem;
          color: $color-text;
        }

        &.soon {
          font-size: 2.4rem;
          margin-top: 1.6rem;
        }
      }
    }
  }
}
