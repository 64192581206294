.update-app {
  ion-header {
    background: $color-background;

    .close {
      padding: 10px;
      float: right;
      cursor: pointer;

      svg {
        height: 2rem;
      }
    }
  }

  .container {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: -44px;

    h2 {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;
      margin-bottom: 0.2rem;
    }

    p {
      white-space: pre-wrap;
      font-weight: $fontweight-medium;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: $color-dark;
    }

    button {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--flat(var(--appTint), $color-white);
      margin-top: 1.6rem;
    }
  }
}
