/*------------------------------------*\
    App | Search
\*------------------------------------*/
.search {
  height: 100%;
  margin-top: calc(0.8 * var(--safeAreaTop));

  ion-content {
    --padding-top: 0;
  }

  &--map-results {
    top: 40vh;
    --offset-bottom: -40vh !important;

    .search-count-number {
      font-size: 1.4rem;
      margin: 20px 0;
      text-align: center;

      span {
        font-weight: $fontweight-bold;
      }
    }
  }

  &.suggestions {
    .search__results,
    .search__top__overlay {
      padding-bottom: 7.2rem;
    }
  }

  &__noResults {
    font-size: 1.4rem;
    color: $color-dark;
    padding: 6.4rem;
    text-align: center;
  }

  &__top {
    height: auto;
    width: 100%;
    position: fixed;

    &__overlay {
      margin-top: calc(0.8 * var(--safeAreaTop));
      z-index: 100;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $color-background;
      opacity: 0;
      transition: 260ms ease;
      transform: translateY(-100%);
      overflow-y: hidden;

      &__inner {
        max-width: 76.8rem;
        margin: 0 auto;
        padding: 7.2rem 0.8rem;
        display: flex;
        flex-direction: column;

        > *:not(:last-child) {
          margin-bottom: 0.8rem;
        }

        ion-spinner {
          align-self: center;
        }
      }

      &__filters {
        display: flex;
        flex-direction: row;
        justify-content: center;

        span {
          display: inline-block;
          margin: 0 0.4rem;
          border-radius: 0.6rem;
          border: 0.1rem solid $color-dark;
          font-weight: $fontweight-medium;
          font-size: 1.4rem;
          line-height: 1.6rem;
          color: $color-dark;
          padding: 0.5rem 0.8rem;
          background: $color-white;
          transition: 260ms ease;
          cursor: pointer;

          &:hover,
          &.active {
            color: $color-white;
            background: $color-dark;
          }
        }
      }

      &.active {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &__field {
      position: absolute;
      z-index: 120;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 76.8rem;
      height: 6.4rem;
      background: $color-white;
      box-shadow: 0 0.2rem 0.4rem rgba($color-black, 0.06);
      border-radius: 0 0 2.4rem 2.4rem;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        appearance: none;
        border: 0;
        outline: 0;
        padding: 0 5.6rem 0 7.2rem;
        font-weight: $fontweight-medium;
        font-size: 1.8rem;
        line-height: normal;
        color: $color-dark;
        border-radius: 0 0 2.4rem 2.4rem;
      }

      span {
        position: absolute;
        z-index: 2;
        top: 50%;
      }

      span.search {
        left: 2.4rem;
        margin-top: -1.4rem;
        width: 2.8rem;
        height: 2.8rem;
        pointer-events: none;

        svg path {
          fill: var(--appTint);
        }
      }

      span.close {
        right: 1.6rem;
        margin-top: -2rem;
        width: 4rem;
        height: 4rem;
        padding: 0.8rem;
        cursor: pointer;

        svg {
          transition: 260ms ease;
        }

        &:hover {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }

    &__map {
      width: 100%;
      height: 40vh;
      position: relative;
      display: flex;

      &__locate {
        position: absolute;
        right: 10px;
        bottom: 10px;
        background-color: $color-white;
        box-shadow: 0.1rem 0.1rem 1rem 0.1rem rgba($color-black, 0.2);
        border-radius: 4px;
        padding: 6px;
        width: 30px;
        height: 30px;

        svg {
          fill: $color-grey--dark;
        }

        &.located {
          svg {
            fill: $color-blue;
          }
        }
      }

      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $color-grey--light;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      app-map {
        width: 100%;
        height: 100%;

        .marker {
          background-image: url('/assets/icons/marker.svg');
          background-size: 3.6rem 4.8rem;
          width: 3.6rem;
          height: 4.8rem;
          cursor: pointer;
          display: flex;
          justify-content: center;

          .icon {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 50%;
            margin-top: 0.2rem;
          }
        }
      }
    }
  }

  &__results {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;

    > app-search-item:not(:last-child) > .card.card--project {
      margin-bottom: 0.8rem;
    }

    > app-search-empty {
      button {
        @include button;
        @include button--m;
        @include button--flat(var(--appTint), $color-white);

        margin: 1.6rem 0;
      }

      .empty-list {
        padding-top: 3rem;
      }
    }
  }
}

.plt-desktop {
  app-search-item:hover {
    cursor: pointer;
  }

  .search__top__map__locate {
    bottom: 75px;
  }
}
