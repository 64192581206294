/*------------------------------------*\
    App | Media Slider
\*------------------------------------*/
.media-slider {
  position: relative;
  width: 100%;
  margin-top: 1.2rem;
  height: auto;
  background: rgba($color-border, 0.5);
  border-radius: 0.8rem;
  overflow: hidden;

  swiper-container {
    height: auto;
    width: 100%;
    --swiper-pagination-bullet-inactive-color: white;
    --swiper-pagination-color: white;

    swiper-slide {
      width: 100%;
      height: 23.6rem;
    }

    img,
    video,
    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.8rem;
    }

    .wrapper {
      width: 100%;
      height: 100%;

      &.no-zoom {
        touch-action: none;
      }
    }
  }

  .swiper-zoom-container {
    .media-slider__overlay {
      background-color: unset;
    }
  }

  &__overlay {
    height: 100%;
    width: 100%;
    background-color: (rgba($color-black, 0.3));
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;
    top: 0;
    left: 0;

    .click-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    p {
      color: $color-white;
      text-shadow: 1px 1px 3px $color-dark;
      font-size: 1.6rem;
    }

    .play-button {
      width: 60px;
      height: 60px;
      background: none;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .pdf-button {
      background-color: var(--appTint) !important;
      padding: 10px;
      border-radius: 5px;
      color: $color-white;
    }
  }

  &__sphere {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__pagination {
    position: absolute;
    z-index: 10;
    bottom: 0.8rem;
    right: 1.3rem;
    font-weight: $fontweight-bold;
    font-size: 1.2rem;
    color: $color-white;
    background-color: rgba($color-black, 0.5);
    padding: 2px 15px;
    border-radius: 20px;
  }

  .psv-navbar {
    background: transparent;

    .psv-button {
      background: rgba($color-black, 0.5);
      color: var(--appTint);
      border-radius: 5px 0 5px 0;
    }
  }
}

.plt-desktop {
  swiper-container:hover {
    cursor: pointer;
  }

  .media-slider {
    swiper-container {
      swiper-slide {
        height: 35.6rem;
      }
    }
  }
}
