/*------------------------------------*\
    App | Card
\*------------------------------------*/
.card {
  $self: &;
  padding: 1.6rem;
  background: $color-white;
  box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.04);
  border-radius: 1.6rem;
  text-align: left;

  &__head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1.2rem;

    > div:first-child[tabindex='0'] {
      width: 100%;
      display: flex;
    }
  }

  &__image {
    width: 5.8rem;
    min-width: 5.8rem;
    height: 5.8rem;
    background: $color-grey;
    border-radius: 0.6rem;
    overflow: hidden;

    &.small {
      width: 3.8rem;
      min-width: 3.8rem;
      height: 3.8rem;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.6rem;
      object-fit: cover;
    }
  }

  &__title {
    width: calc(100% - 9.6rem);
    min-width: calc(100% - 9.6rem);
    display: flex;
    flex-direction: column;
    padding-left: 0.8rem;

    > span {
      display: block;
      color: $color-dark;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        span {
          display: block;
          font-weight: $fontweight-medium;
          font-size: 1.4rem;
          line-height: 2rem;
          max-height: 2rem;
          color: $color-dark;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:last-child:not(:first-child) {
            color: rgba($color-dark, 0.5);
          }
        }
      }

      &:last-child {
        font-weight: $fontweight-light;
        font-size: 1.2rem;
        line-height: 1.8rem;
        max-height: 1.8rem;
        white-space: nowrap;
      }
    }
  }

  &__share {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.4rem;
    margin-left: auto;

    span {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__buttons {
    .button {
      font-size: 1.5rem;
      font-weight: 600;
      color: $color-white;
      padding: 0 1.2rem;
      margin: 1.6rem 0.8rem 0 0;
      text-align: center;
      border-radius: 0.8rem;
      line-height: 1.6rem;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      transition: 250ms ease;
      height: 4rem;
    }

    .button--primary {
      background-color: var(--appTint);

      &:hover {
        filter: brightness(90%);
      }
    }

    .button--secondary {
      background-color: $color-white;
      color: $color-grey--dark;
      border: 1px solid $color-grey--dark;

      &:hover {
        background-color: darken($color-grey, 5%);
        color: $color-dark;
      }
    }
  }

  &__new {
    color: var(--appTint);
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;

    h2 {
      font-weight: $fontweight-bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-dark;
      margin-bottom: 0.2rem;

      &.visually-hidden {
        opacity: 0;
        height: 0;
      }
    }

    > a {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 75px;
      text-align: right;
      line-height: 2rem;
      font-size: 1.4rem;
      color: var(--appTint);
      margin-right: 0.8rem;
      background: $color-white;
    }

    .read-more {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: var(--appTint);
      display: inline;
      text-decoration: none;
    }

    > span {
      font-weight: $fontweight-bold;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: rgba($color-dark, 0.4);
    }

    > div {
      font-weight: $fontweight-regular;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-dark;
      overflow: hidden;

      p {
        padding-right: 5px;

        &:last-of-type {
          display: inline;
        }
      }

      a,
      p,
      strong,
      u,
      em,
      li {
        font-weight: $fontweight-regular;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      ul,
      ol {
        padding-left: 3.2rem;
        margin: 1.6rem 0;
      }

      ul li {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }

      strong {
        font-weight: $fontweight-semibold;
      }

      a {
        color: var(--appTint);
      }
    }

    & &__note {
      color: var(--appTint);
      font-size: 1.4rem;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: $fontweight-bold;
      white-space: nowrap;
    }

    button:not(.translation-button) {
      @include button;
      @include button--m;
      @include button--icon;
      @include button--flat(var(--appSupport), $color-white);
      margin-top: 1.6rem;
      align-self: flex-start;
    }
  }

  &__bottom {
    margin-top: 1.2rem;
  }

  &__events {
    padding: 0.8rem;
  }

  &--events {
    border: 2px solid var(--appTint);
    position: relative;
    padding: 1.6rem 0.8rem 1.6rem 0.8rem;

    app-events {
      padding: 0 0.8rem;
    }

    &:has(.sticky-stacked) {
      margin-bottom: 2.4rem !important;
      padding-bottom: 1.6rem;
    }
  }

  &--update {
    padding: 1.6rem 0.8rem;
  }

  &--report {
    padding: 0.4rem;

    app-smart-reporting-map-page {
      position: relative;

      .smart-reporting {
        &__map {
          width: 100%;
          height: 33vh;
          border-radius: 1.6rem;
          overflow: hidden;
          position: static;
        }

        &__filter {
          top: 1.6rem;
          right: 1.6rem;
        }

        &__new {
          bottom: 1.6rem;
        }
      }
    }
  }

  &--update,
  &--events {
    #{ $self }__head,
    #{ $self }__content,
    #{ $self }__bottom {
      padding: 0 0.8rem;
    }
  }

  &__cover-image {
    width: auto;
    height: 8rem;
    background-color: $color-grey;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    border-radius: 1.6rem;
    margin-left: -0.8rem;
    margin-right: -0.8rem;
    margin-top: -1.6rem;
    margin-bottom: 1.6rem;
  }
}

.plt-desktop {
  .card {
    &__share:hover {
      cursor: pointer;
      svg {
        fill: var(--appTint);
      }
    }
  }

  .card:not(.welcome):not(.card--summary) {
    .card__title {
      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .read-more:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
